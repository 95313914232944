'use strict';

import { store } from '../store';

import languagesMap from '../data/languages';
import translations from '../data/i18n';

export const openLanguageModal = function(init = false) {
    const chatModal = document.querySelector('#chat-modal');
    const chatModalLanguages = document.querySelector(
        '#chat-modal-languages',
    );
    const isChatModalVisible = chatModal.style.display !== 'none';

    let languageButtonsHTML = '';

    store.alias.languages.forEach(language => {
        languageButtonsHTML +=
            `<div class="button-language" onClick="$_omb.` +
            (init ? 'setLanguageAndContinue' : 'setLanguage') +
            `('${language}')">${languagesMap.get(language)}</div>`;
    });

    chatModalLanguages.innerHTML = languageButtonsHTML;

    if (!isChatModalVisible) {
        const chatModalText = document.querySelector(
            '#chat-modal-text',
        );
        const chatModalButtons = document.querySelector(
            '#chat-modal-buttons',
        );

        chatModal.style.display = 'block';
        chatModalButtons.style.display = 'none';
        chatModalLanguages.style.display = 'flex';
        chatModalText.innerHTML =
            translations[store.i18n.language][
                'seleccionaIdiomaChatbot'
            ];
    }
};

export const setLanguageAndContinue = async function(language) {
    setLanguage(language);

    await $_omb.openChatWindow();
};

export const setLanguage = function(language) {
    const chatModal = document.querySelector('#chat-modal');
    const chatMessages = $_omb.getChatMessagesContainer();
    const isChatModalVisible = chatModal.style.display !== 'none';

    store.i18n.language = language;
    localStorage.setItem(
        `$omb_language_selected_${store.bot._id}`,
        language,
    );

    if (isChatModalVisible) {
        const chatDropdown = document.querySelector('#chat-dropdown');

        chatModal.style.display = 'none';
        chatDropdown.style.display = 'none';
    }

    chatMessages.style.height = '380px';

    doTraslation();
};

export const doTraslation = function() {
    const elements = document.querySelectorAll('*[translate]');
    const typingBar = document.querySelector('#typing-bar');

    for (const element of elements) {
        const languageKey = element.getAttribute('translate');
        const translationText =
            translations[store.i18n.language][languageKey];
        element.innerHTML = translationText;
    }

    typingBar.placeholder =
        translations[store.i18n.language]['escribeTuPregunta'];

    if (store.bot.gdpr.privacy_menu_text_other) {
        const chatDropdownPolicyUrl = document.querySelector(
            '#chat-dropdown-policy-url',
        );

        chatDropdownPolicyUrl.innerHTML =
            translations[store.i18n.language][
                'politicaDePrivacidadOtro'
            ];
    }
};

export default {
    openLanguageModal,
    setLanguageAndContinue,
    setLanguage,
    doTraslation,
};
