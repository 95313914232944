'use strict';

const translation = {
    politicaDePrivacidad: 'Política de Privacitat',
    politicaDePrivacidadOtro: 'Protecció de dades',
    seleccionarIdioma: 'Seleccionar Idioma',
    olvidarMisDatos: 'Oblidar les meves dades',
    sobreLosCreadores: 'Sobre els creadors',
    politicadePrivacidadMayus: 'POLÍTICA DE PRIVACITAT',
    procedimientoIrreversible:
        'Aquest procediment és irreversible, si continues oblidaré tot sobre tu',
    seleccionaIdiomaChatbot:
        "Selecciona l'idioma en què vols que parli el teu chatbot",
    escribeTuPregunta: 'Escriu la teva pregunta',
    escribiendo: 'Escrivint...',
    error:
        "Ara mateix no puc atendre't, si us plau torna a carregar la pàgina, si tot i així segueixo sense respondre espera uns minuts. Disculpa les molèsties.",
};

export default translation;
