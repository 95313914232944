'use strict';

import xss from 'xss';

import { store } from '../store.js';
import { getCookie } from '../utils';
import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider';
import utils from '../utils';

const SURVEY_ANSWER_SETS = {
    stars: {
        1: '&#11088;',
        2: '&#11088;',
        3: '&#11088;',
        4: '&#11088;',
        5: '&#11088;',
    },
    emojis: {
        1: '&#128532',
        2: '&#128577;',
        3: '&#128528;',
        4: '&#128578;',
        5: '&#128513;',
    },
};

export function printText(text, messageClass) {
    const newMessage = document.createElement('li');
    const html = $_omb.transformTagsToHTML(text);
    const messagesContainer = $_omb.getChatMessagesContainer();

    newMessage.classList.add(messageClass);
    newMessage.innerHTML = xss(html);

    messagesContainer.appendChild(newMessage);

    if (messageClass === 'chat-message-user') {
        $_omb.scrollToBottom();
        store.app.lastMessageUserScroll = messagesContainer.scrollTop;
    }
}

export function printImages(images, messageClass) {
    const messagesContainer = $_omb.getChatMessagesContainer();
    const newMessage = document.createElement('li');
    const newImageContainer = document.createElement('div');

    newMessage.classList.add(messageClass);
    newMessage.classList.add('chat-message-bot-image');

    newImageContainer.classList.add(
        'chat-message-bot-image-container',
    );

    for (const image of images) {
        const newImage = document.createElement('img');

        newImage.src = image.imageUrl;
        newImageContainer.appendChild(newImage);
    }

    newMessage.appendChild(newImageContainer);
    messagesContainer.appendChild(newMessage);
}

export function printVideos(videos, messageClass) {
    const messagesContainer = $_omb.getChatMessagesContainer();
    const newMessage = document.createElement('li');
    const newVideoContainer = document.createElement('div');

    newMessage.classList.add(messageClass);
    newMessage.classList.add('chat-message-bot-media');

    newVideoContainer.classList.add(
        'chat-message-bot-media-container',
    );

    for (const video of videos) {
        const newIFrame = document.createElement('iframe');

        newIFrame.src = video.videoUrl;
        newIFrame.style.maxWidth = '100%';
        newIFrame.style.maxHeight = '100%';
        newIFrame.type = 'text/html';
        newIFrame.allow =
            'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';

        newIFrame.setAttribute('frameborder', '0');
        newIFrame.setAttribute('allowfullscreen', 'true');

        newVideoContainer.appendChild(newIFrame);
    }

    newMessage.appendChild(newVideoContainer);
    messagesContainer.appendChild(newMessage);
}

export function printCards(cards, messageClass) {
    const newMessage = document.createElement('li');
    const newSlider = document.createElement('div');
    const messagesContainer = $_omb.getChatMessagesContainer();
    const shouldAddSlider = cards.length > 1;

    newMessage.classList.add(messageClass);

    newSlider.classList.add(
        shouldAddSlider ? 'tiny-chat-slider-cards' : 'tiny-chat-card',
    );

    for (const card of cards) {
        const newCard = document.createElement('div');

        newCard.classList.add('tiny-chat-message-bot-card');

        if (!shouldAddSlider) {
            newCard.style.position = 'inherit';
        }

        if (card.imageUrl) {
            const newImage = document.createElement('img');

            newImage.classList.add('bot-card-img-top');
            newImage.src = card.imageUrl;

            newCard.appendChild(newImage);
        }

        if (card.title) {
            const newTitle = document.createElement('h5');
            const title =
                card.title.length > 55
                    ? `${card.title.substring(0, 55)}...`
                    : card.title;

            newTitle.appendChild(document.createTextNode(title));
            newTitle.classList.add('bot-card-title');

            newCard.appendChild(newTitle);
        }

        if (card.subtitle) {
            const newSubtitle = document.createElement('p');

            newSubtitle.innerHTML = xss(card.subtitle);
            newSubtitle.classList.add('bot-card-subtitle');

            newCard.appendChild(newSubtitle);
        }

        if (card.buttons) {
            const newButtonsGroup = document.createElement('ul');

            newButtonsGroup.classList.add(
                'bot-card-list-group-flush',
            );

            for (const button of card.buttons) {
                const newButtonContainer = document.createElement(
                    'li',
                );

                if (button.type === 'url') {
                    const newLink = document.createElement('a');
                    const newLinkText = document.createTextNode(
                        button.text,
                    );
                    const newLinksContainer = document.createElement(
                        'div',
                    );

                    newLink.href = button.value;
                    newLink.target = '_blank';
                    newLink.rel = 'noopener noreferrer';

                    newLinksContainer.classList.add(
                        'bot-chat-message-card-link',
                    );

                    newLinksContainer.appendChild(newLinkText);
                    newLink.appendChild(newLinksContainer);
                    newButtonContainer.appendChild(newLink);
                } else {
                    const newButton = document.createElement(
                        'button',
                    );

                    newButton.setAttribute(
                        'event',
                        button.type === 'event',
                    );
                    newButton.classList.add(
                        'bot-chat-message-card-button',
                    );

                    newButton.value = button.value;
                    newButton.innerHTML = xss(button.text);

                    newButton.addEventListener(
                        'click',
                        $_omb.handleButtonEvent,
                    );

                    newButtonContainer.appendChild(newButton);
                }

                newButtonsGroup.appendChild(newButtonContainer);
            }

            newCard.appendChild(newButtonsGroup);
        }

        newSlider.appendChild(newCard);
    }

    newMessage.appendChild(newSlider);
    messagesContainer.appendChild(newMessage);

    if (shouldAddSlider) {
        const sliderCount = document.querySelectorAll(
            '.tiny-chat-slider-cards',
        ).length;

        newSlider.setAttribute(
            'id',
            `tiny-chat-slider-${sliderCount}`,
        );

        tns({
            container: `#tiny-chat-slider-${sliderCount}`,
            items: 1,
            navPosition: 'bottom',
            mouseDrag: true,
            slideBy: 'page',
            controls: false,
            loop: false,
        });

        let sliderNavs = document.getElementsByClassName('tns-nav');

        for (let i = 0; i < sliderNavs.length; i++) {
            sliderNavs[i].style.display = 'flex';
            sliderNavs[i].style.justifyContent = 'center';
        }
    }
}

export function printButtons(buttons) {
    const buttonsSection = document.querySelector(
        '#chat-buttons-response',
    );
    const messagesContainer = $_omb.getChatMessagesContainer();
    const isButtonsSectionVisible =
        buttonsSection.style.display !== 'none';
    const textOverflowLength = 38;
    const textLength =
        buttons.length === 1 ? buttons[0].text.length : 100;
    const buttonsCarousel = document.createElement('div');

    if (isButtonsSectionVisible) {
        $_omb.clearButtonsSection();
    }

    buttonsSection.style.display = 'block';
    buttonsSection.classList.add('swing-in-bottom-fwd');
    buttonsSection.classList.remove('swing-out-bottom-bck');

    messagesContainer.style.height = '338px';

    buttonsCarousel.id = 'buttons-carousel';
    buttonsCarousel.addEventListener(
        'mousewheel',
        buttonsHorizontalScrollingEvent,
    );

    for (const button of buttons) {
        let newButton;

        if (button.type === 'url') {
            newButton = document.createElement('a');

            newButton.target = '_blank';
            newButton.rel = 'noopener noreferrer';
            newButton.href = button.value;
            newButton.innerText = button.text;
        } else {
            newButton = document.createElement('button');

            newButton.value = button.notification
                ? button.key.join(',')
                : button.value;
            newButton.innerText = button.text;
            newButton.setAttribute('event', button.type === 'event');

            newButton.addEventListener(
                'click',
                $_omb.handleButtonEvent,
            );

            if (button.notification) {
                newButton.setAttribute(
                    'notification',
                    button.notification ? button.notification : false,
                );
                newButton.setAttribute(
                    'notificationValue',
                    button.value,
                );
            }
        }

        newButton.classList.add('button-chat-message');
        newButton.style.fontSize = store.app.style.buttonFontSize;

        buttonsCarousel.appendChild(newButton);
    }

    if (textLength > textOverflowLength) {
        const newLeftArrow = createArrow('left');
        const newRightArrow = createArrow('right');

        buttonsCarousel.appendChild(newLeftArrow);
        buttonsCarousel.appendChild(newRightArrow);
    }

    buttonsSection.appendChild(buttonsCarousel);
}

export function createArrow(direction) {
    const arrow = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg',
    );
    const arrowPath1 = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path',
    );
    const arrowPath2 = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path',
    );

    arrow.id = `arrow_${direction}_buttons`;
    arrow.classList.add('material-icons');

    arrow.setAttribute('aria-hidden', 'true');
    arrow.setAttribute('viewbox', '0 0 24 24');
    arrow.setAttribute('width', '24px');
    arrow.setAttribute('height', '24px');

    arrowPath1.setAttribute(
        'd',
        direction === 'left'
            ? 'M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z'
            : 'M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z',
    );

    arrowPath2.setAttribute('d', 'M0 0h24v24H0z');
    arrowPath2.setAttribute('fill', 'none');

    arrow.appendChild(arrowPath1);
    arrow.appendChild(arrowPath2);

    arrow.addEventListener('click', buttonsHorizontalScrollingEvent);

    if (direction === 'left') {
        arrow.style.display = 'none';
    }

    return arrow;
}

export function printForm(form) {
    const messagesContainer = $_omb.getChatMessagesContainer();

    const newMessage = document.createElement('li');
    const newForm = document.createElement('form');
    const newFieldset = document.createElement('fieldset');
    const newTitle = document.createElement('p');
    const newCloseButton = document.createElement('a');
    const closeButtonText = document.createElement('i');
    const newSendButton = document.createElement('button');
    const sendButtonText = document.createElement('span');

    closeButtonText.classList.add('material-icons');
    closeButtonText.innerText = 'x';
    closeButtonText.style.padding = '0 10px';
    closeButtonText.style.fontStyle = 'normal';
    closeButtonText.style.fontSize = '18px';
    closeButtonText.style.fontFamily = 'Arial';

    newCloseButton.classList.add('form-button-close');
    newCloseButton.setAttribute('onClick', '$_omb.closeForm()');

    sendButtonText.innerText = form.button.text;
    newSendButton.setAttribute('type', 'button');
    newSendButton.classList.add('form-button-send');
    newSendButton.style.backgroundColor =
        store.alias.styles.primary_color || '#696969';

    const rgb = utils.hexToRgb(store.alias.styles.primary_color);

    newSendButton.style.color =
        rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114 > 176
            ? '#000000'
            : '#ffffff';

    newSendButton.addEventListener(
        'click',
        async function() {
            await submitForm(form);
        },
        false,
    );

    newSendButton.appendChild(sendButtonText);
    newCloseButton.appendChild(closeButtonText);

    if (form.title) {
        newTitle.innerText = form.title;
        newTitle.classList.add('form-title');
    }

    newMessage.setAttribute('id', 'form-chat-message');

    for (const input of form.inputs) {
        const newInput = document.createElement('input');

        newInput.setAttribute('name', input.name);
        newInput.setAttribute('type', input.type);

        if (input.placeholder) {
            newInput.setAttribute('placeholder', input.placeholder);
        }

        if (input.value) {
            newInput.setAttribute('value', input.value);
        }

        if (input.type === 'file' && input.accept) {
            newInput.setAttribute('accept', input.accept);
        }

        if (input.labelText) {
            const newLabel = document.createElement('label');

            newLabel.setAttribute('for', input.name);
            newLabel.innerText = input.labelText;

            newFieldset.appendChild(newLabel);
        }

        newFieldset.appendChild(newInput);
    }

    newForm.addEventListener(
        'keypress',
        async function(event) {
            if (event.key === 13) {
                await submitForm(form);
            }
        },
        false,
    );

    newFieldset.appendChild(newSendButton);
    newForm.appendChild(newCloseButton);
    newForm.appendChild(newTitle);
    newForm.appendChild(newFieldset);
    newMessage.appendChild(newForm);

    messagesContainer.appendChild(newMessage);
}

export async function submitForm(form) {
    let body = {};

    for (const input of form.inputs) {
        const inputElement = document.querySelector(
            `input[name="${input.name}"]`,
        );

        body[input.name] = inputElement.value;
    }

    body['userToken'] = getCookie(`$omb_user_${store.bot._id}`);
    body['userLanguage'] =
        localStorage.getItem(
            `$omb_language_selected_${store.bot._id}`,
        ) || 'es';

    const fetchOptions = {
        method: form.method,
        ...(form.headers ? { headers: form.headers } : {}),
        body: JSON.stringify(body),
    };

    const response = await (
        await fetch(form.url, fetchOptions)
    ).json();

    if (response) {
        closeForm();

        if (response.message) {
            for (const message of response.message) {
                $_omb.addResponseMessage(message);
            }
        }

        if (response.headers) {
            document.cookie = response.headers['set-cookie'];
        }
    }
}

export function closeForm() {
    const formMessage = document.querySelector('#form-chat-message');

    formMessage.remove();
}

export function printSurveyQuestion(message, messageClass) {
    const survey = message.payload.survey;
    const question = survey.question;
    const newMessage = document.createElement('li');

    const messagesContainer = document.querySelector(
        '#chat-messages',
    );

    newMessage.classList.add(messageClass, 'chat-message-bot-survey');

    const html = $_omb.transformTagsToHTML(
        question.text[store.i18n.language],
    );
    newMessage.innerHTML = html;

    const newButtonsContainer = document.createElement('div');
    newButtonsContainer.classList.add('survey-buttons-group');

    const surveyAnswerSet =
        SURVEY_ANSWER_SETS[question.answer] ||
        SURVEY_ANSWER_SETS.stars;

    // Flex-row is displayed reversed
    for (const [value, text] of Object.entries(
        surveyAnswerSet,
    ).reverse()) {
        const newButton = document.createElement('button');

        newButton.value = value;
        newButton.innerHTML = text;

        if (question.answer === 'stars') {
            newButton.classList.add('star');
        }

        if (question.response) {
            newButton.classList.add('star');
            newButton.classList.add('disabled');

            if (value === question.response.toString()) {
                newButton.classList.add('selected');
            }
        } else {
            newButton.addEventListener('click', event => {
                $_omb.handleSurveyEvent(event, message);
            });
        }

        newButtonsContainer.appendChild(newButton);
    }

    newMessage.appendChild(newButtonsContainer);
    messagesContainer.appendChild(newMessage);

    setTimeout(() => {
        $_omb.scrollToBottom();
    }, 10);
}

export function buttonsHorizontalScrollingEvent(event) {
    const carousel = document.getElementById('buttons-carousel');

    let delta = '';
    let maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;

    if (event.type == 'click') {
        document.getElementById('chat-body').style.overflowY =
            'hidden';
        if (event.target.id == 'arrow_right_buttons') {
            delta = -1;
        } else {
            delta = 1;
        }
        carousel.scrollLeft -= delta * 80;
    } else {
        document.getElementById('chat-body').style.overflowY =
            'hidden';
        delta = Math.max(
            -1,
            Math.min(1, event.wheelDelta || -event.detail),
        );
        carousel.scrollLeft -= delta * 40;
    }

    if (parseInt(carousel.scrollLeft) == maxScrollLeft) {
        document.getElementById('arrow_right_buttons').style.display =
            'none';
        document.getElementById('arrow_left_buttons').style.display =
            'block';
        carousel.style.margin = '0em 0em 0em 2.5em';
    } else if (carousel.scrollLeft == 0) {
        document.getElementById('arrow_right_buttons').style.display =
            'block';
        document.getElementById('arrow_left_buttons').style.display =
            'none';
        carousel.style.margin = '0em 2.5em 0em 0em';
    } else {
        document.getElementById('arrow_right_buttons').style.display =
            'block';
        document.getElementById('arrow_left_buttons').style.display =
            'block';
        carousel.style.margin = '0em 2.5em 0em 2.5em';
    }

    event.preventDefault();
}

export default {
    printText,
    printCards,
    printImages,
    printVideos,
    printButtons,
    printForm,
    submitForm,
    closeForm,
    printSurveyQuestion,
};
