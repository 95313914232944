'use strict';

const languagesMap = new Map([
    ['af', 'Afrikaans'],
    ['sq', 'Albanian'],
    ['am', 'Amharic'],
    ['ar', 'Arabic'],
    ['hy', 'Armenian'],
    ['az', 'Azerbaijani'],
    ['eu', 'Basque'],
    ['be', 'Belarusian'],
    ['bn', 'Bengali'],
    ['bs', 'Bosnian'],
    ['bg', 'Bulgarian'],
    ['va', 'Valencià'],
    ['ca', 'Català'],
    ['ceb', 'Cebuano'],
    ['cn', 'Chinese'],
    ['co', 'Corsican'],
    ['hr', 'Croatian'],
    ['cs', 'Czech'],
    ['da', 'Danish'],
    ['nl', 'Dutch'],
    ['en', 'English'],
    ['eo', 'Esperanto'],
    ['et', 'Estonian'],
    ['fi', 'Finnish'],
    ['fr', 'French'],
    ['fy', 'Frisian'],
    ['gl', 'Galician'],
    ['ka', 'Georgian'],
    ['de', 'German'],
    ['el', 'Greek'],
    ['gu', 'Gujarati'],
    ['ht', 'Haitian_Creole'],
    ['ha', 'Hausa'],
    ['haw', 'Hawaiian'],
    ['he', 'Hebrew'],
    ['hi', 'Hindi'],
    ['hmn', 'Hmong'],
    ['hu', 'Hungarian'],
    ['is', 'Icelandic'],
    ['ig', 'Igbo'],
    ['id', 'Indonesian'],
    ['ga', 'Irish'],
    ['it', 'Italian'],
    ['ja', 'Japanese'],
    ['jw', 'Javanese'],
    ['kn', 'Kannada'],
    ['kk', 'Kazakh'],
    ['km', 'Khmer'],
    ['ko', 'Korean'],
    ['ku', 'Kurdish'],
    ['ky', 'Kyrgyz'],
    ['lo', 'Lao'],
    ['la', 'Latin'],
    ['lv', 'Latvian'],
    ['lt', 'Lithuanian'],
    ['lb', 'Luxembourgish'],
    ['mk', 'Macedonian'],
    ['mg', 'Malagasy'],
    ['ms', 'Malay'],
    ['ml', 'Malayalam'],
    ['mt', 'Maltese'],
    ['mi', 'Maori'],
    ['mr', 'Marathi'],
    ['mn', 'Mongolian'],
    ['my', 'Myanmar'],
    ['ne', 'Nepali'],
    ['no', 'Norwegian'],
    ['ny', 'Nyanja'],
    ['ps', 'Pashto'],
    ['fa', 'Persian'],
    ['pl', 'Polish'],
    ['pt', 'Portuguese'],
    ['pa', 'Punjabi'],
    ['ro', 'Romanian'],
    ['ru', 'Russian'],
    ['sm', 'Samoan'],
    ['gd', 'Scots_Gaelic'],
    ['sr', 'Serbian'],
    ['st', 'Sesotho'],
    ['sn', 'Shona'],
    ['sd', 'Sindhi'],
    ['si', 'Sinhala'],
    ['sk', 'Slovak'],
    ['sl', 'Slovenian'],
    ['so', 'Somali'],
    ['es', 'Castellano'],
    ['su', 'Sundanese'],
    ['sw', 'Swahili'],
    ['sv', 'Swedish'],
    ['tl', 'Tagalog'],
    ['tg', 'Tajik'],
    ['ta', 'Tamil'],
    ['te', 'Telugu'],
    ['th', 'Thai'],
    ['tr', 'Turkish'],
    ['uk', 'Ukrainian'],
    ['ur', 'Urdu'],
    ['uz', 'Uzbek'],
    ['vi', 'Vietnamese'],
    ['cy', 'Welsh'],
    ['xh', 'Xhosa'],
    ['yi', 'Yiddish'],
    ['yo', 'Yoruba'],
    ['zu', 'Zulu'],
]);

export default languagesMap;
