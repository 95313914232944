'use strict';

const translation = {
    politicaDePrivacidad: 'Política de Privacidad',
    politicaDePrivacidadOtro: 'Protección de datos',
    seleccionarIdioma: 'Seleccionar Idioma',
    olvidarMisDatos: 'Olvidar mis datos',
    sobreLosCreadores: 'Sobres los creadores',
    politicadePrivacidadMayus: 'POLÍTICA DE PRIVACIDAD',
    procedimientoIrreversible:
        'Este procedimiento es irreversible, si continúas voy a olvidar todo sobre ti',
    seleccionaIdiomaChatbot:
        'Selecciona el idioma en el que deseas que hable tu chatbot',
    escribeTuPregunta: 'Escribe tu pregunta',
    escribiendo: 'Escribiendo...',
    error:
        'Ahora mismo no puedo atenderte, por favor vuelve a cargar la página, si aun así sigo sin responder aguarda unos minutos. Disculpa las molestias.',
};

export default translation;
