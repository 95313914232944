'use strict';

import { checkApiKey } from '../utils';

import botService from '../services/bot';

export async function init(options = { alias: null }) {
    try {
        checkApiKey();

        await botService.init({
            alias: options.alias,
        });
    } catch (error) {
        console.error({ error });
    }
}

export default {
    init,
};
