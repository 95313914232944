'use strict';

import { store } from '../store';
import { checkApiKey } from '../utils';

import constructors from '../constructors';

import botService from '../services/bot';
import statusService from '../services/status';

export async function init(options = { key: null, alias: null }) {
    try {
        store.api.key = options.key;
        checkApiKey();

        await botService.init({
            alias: options.alias,
        });

        await statusService.get();

        constructors.createHtmlContainers();
        constructors.cheateHtmlChat();
        constructors.setChatSizes();
        constructors.setChatEvents();
        constructors.createVoiceWebElements();

        $_omb.loadBotProperties();
        $_omb.loadAgentProperties();
        $_omb.loadCustomerProperties();

        $_omb.loadCustomerStyles();
        $_omb.loadCustomerScripts();
    } catch (error) {
        console.error({ error });
    }
}

export async function prepareInsecureInit() {
    try {
        const baseURL = `${store.api.url}/public/key`;
        const fetchOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { bot } = await (
            await fetch(baseURL, fetchOptions)
        ).json();

        store.api.key = bot.integrations.web.api_key;
        store.bot = bot;
    } catch (error) {
        console.error({ error });
    }
}

export async function setApiKey(options = { key: null }) {
    try {
        store.api.key = options.key;
        checkApiKey();
    } catch (error) {
        console.error({ error });
    }
}

export default {
    init,
    setApiKey,
    prepareInsecureInit,
};
