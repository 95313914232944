'use strict';

import { store } from '../store';
import utils from '../utils';

import constructors from '../constructors';

import statusService from '../services/status.js';
import socketService from '../services/socket';

export async function get() {
    const currentConversationId = utils.getCookie(
        `$omb_conversation_${store.bot._id}`,
    );

    if (currentConversationId) {
        store.conversation._id = currentConversationId;

        const baseURL = `${store.api.url}/public/conversations/${currentConversationId}`;
        const fetchOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `API-KEY ${store.api.key}`,
            },
        };

        const { conversation } = await (
            await fetch(baseURL, fetchOptions)
        ).json();

        if (conversation) {
            store.conversation = conversation;
            constructors.createHtmlMessages();

            if (store.socket.client) {
                await socketService.kill(async () => {
                    await statusService.update(
                        {
                            origin: store.session.origin,
                            online: false,
                            typing: false,
                            attended: store.status.attended,
                            userName: store.user.name,
                        },
                        true,
                    );
                });
            }

            socketService.create(async () => {
                await statusService.update(
                    {
                        origin: store.session.origin,
                        online: true,
                        typing: false,
                        attended: store.status.attended,
                        userName: store.user.name,
                    },
                    true,
                );
            });
        }
    } else {
        const firstMessage = document.querySelector(
            '.chat-message-bot-first',
        );

        if (!firstMessage) {
            for (const message of store.alias.welcome_message.web[
                store.i18n.language
            ]) {
                $_omb.addResponseMessage(message);
            }
        }
    }
}

export async function create() {
    const baseURL = `${store.api.url}/public/conversations`;
    const fetchOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `API-KEY ${store.api.key}`,
        },
        body: JSON.stringify({
            company: store.bot.company,
            bot: store.bot._id,
            alias: store.alias._id,
            user: store.user._id,
            language: store.i18n.language,
            integration: 'web',
        }),
    };

    const { conversation } = await (
        await fetch(baseURL, fetchOptions)
    ).json();

    if (conversation) {
        store.conversation = conversation;
        utils.deleteCookie(`$omb_conversation_${store.bot._id}`);
        utils.setCookie(
            `$omb_conversation_${store.bot._id}`,
            conversation._id,
        );

        utils.deleteCookie(`$omb_alias_${conversation._id}`);
        utils.setCookie(
            `$omb_alias_${conversation._id}`,
            store.alias._id,
        );

        if (store.socket.client) {
            await socketService.kill(async () => {
                await statusService.update(
                    {
                        origin: store.session.origin,
                        online: false,
                        typing: false,
                        attended: store.status.attended,
                        userName: store.user.name,
                    },
                    true,
                );
            });
        }

        socketService.create(async () => {
            await statusService.update(
                {
                    origin: store.session.origin,
                    online: true,
                    typing: false,
                    attended: store.status.attended,
                    userName: store.user.name,
                },
                true,
            );
        });
    }
}

export default {
    get,
    create,
};
