const avatarHtml = `
<div id="avatar-minimized-container">
  <button id="avatar-close-button" onclick="$_omb.hideAvatar()" style="width: 1.25em; height: 1.25em;">
    <div id="close-bubble" class="fade-in">
      <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" fill="#444444" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
      </svg>
    </div>
  </button>

  <button onclick="$_omb.openChatWindow()" id="avatar-row">
    <div id="call-to-action-bubble" class="fade-in-right"></div>
    <img id="avatar-minimized" alt="Avatar chatbot" class="heartbeat" tabindex="0">
  </button>
</div>
`;

export default avatarHtml;
