'use strict';

import { store } from '../store';

export async function create(answer) {
    const baseURL = `${store.api.url}/public/surveys/responses`;
    const fetchOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `API-KEY ${store.api.key}`,
        },
        body: JSON.stringify(answer),
    };

    const answerResponse = await (
        await fetch(baseURL, fetchOptions)
    ).json();

    return answerResponse.data;
}

export default {
    create,
};
