'use strict';

import { store } from '../store';

export function loadCustomerStyles() {
    if (
        store.bot.services &&
        store.bot.services.custom_css &&
        store.bot.services.custom_css.active
    ) {
        const css = store.bot.services.custom_css.code;
        const head = document.getElementsByTagName('head')[0];
        const style = document.createElement('style');

        style.setAttribute('type', 'text/css');

        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
        head.appendChild(style);
    }
}

export function loadCustomerScripts() {
    if (
        store.bot.services &&
        store.bot.services.custom_js &&
        store.bot.services.custom_js.active
    ) {
        const js = store.bot.services.custom_js.code;
        eval(js);
    }
}

export default {
    loadCustomerStyles,
    loadCustomerScripts,
};
