'use strict';

import { store } from '../store';

import chatHtml from '../templates/chat';
import avatarHtml from '../templates/avatar';
import languageSelectorHtml from '../templates/languageSelector';

import statusService from '../services/status';

export function createHtmlContainers() {
    const appContainer = document.createElement('div');
    const botContainer = document.createElement('div');
    const avatarContainer = document.createElement('div');

    appContainer.setAttribute('id', 'app-db1mb-container');
    botContainer.setAttribute('id', 'bot-container');
    avatarContainer.setAttribute('id', 'avatar-container');

    document.body.appendChild(appContainer);
    appContainer.appendChild(botContainer);
    appContainer.appendChild(avatarContainer);
}

export function cheateHtmlChat() {
    const botContainer = document.querySelector('#bot-container');
    const avatarContainer = document.querySelector(
        '#avatar-container',
    );
    const botHasTranslations = store.i18n.languages.length > 1;

    botContainer.innerHTML = chatHtml;
    avatarContainer.innerHTML = avatarHtml;

    if (botHasTranslations) {
        const chatDropDownPolicyUrl = document.querySelector(
            '#chat-dropdown-policy-url',
        );

        chatDropDownPolicyUrl.insertAdjacentHTML(
            'afterend',
            languageSelectorHtml,
        );
    }
}

export function setChatSizes() {
    const minimizedAvatar = document.querySelector(
        '#avatar-minimized',
    );
    const callToActionBubble = document.querySelector(
        '#call-to-action-bubble',
    );

    if (
        typeof window.orientation !== 'undefined' ||
        navigator.userAgent.indexOf('IEMobile') !== -1
    ) {
        const metaTags = document.head.getElementsByTagName('meta');
        let hasViewportMeta = false;

        for (const meta of metaTags) {
            const isViewportMeta =
                meta.hasAttribute('name') &&
                meta.name == 'viewport' &&
                meta.hasAttribute('content');

            if (isViewportMeta) {
                hasViewportMeta = true;

                meta.setAttribute(
                    'content',
                    'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0',
                );

                break;
            }
        }

        if (hasViewportMeta) {
            minimizedAvatar.style.width = '88px';
            minimizedAvatar.style.height = '88px';

            if (callToActionBubble) {
                callToActionBubble.style.padding = '8px 16px';
            }
        }
        document.getElementById('bot-container').style.width = '100%';
        document.getElementById('bot-container').style.height =
            '100%';
    } else {
        document.getElementById('bot-container').style.marginRight =
            '20px';
    }

    if (store.alias.styles.size === 'small') {
        minimizedAvatar.style.height = '66px';
        minimizedAvatar.style.width = '66px';
    } else if (store.alias.styles.size === 'medium') {
        minimizedAvatar.style.height = '83px';
        minimizedAvatar.style.width = '83px';
    } else {
        minimizedAvatar.style.height = '100px';
        minimizedAvatar.style.width = '100px';
    }
}

export function setChatEvents() {
    document
        .getElementById('typing-bar')
        .addEventListener('input', async function(event) {
            $_omb.handleVoiceInputText(event.target.value.length);

            if (
                store.status.attended &&
                store.status.attended.employee
            ) {
                if (!store.app.typing.current) {
                    await statusService.update(
                        {
                            origin: store.session.origin,
                            online: true,
                            typing: true,
                            attended: store.status.attended,
                            userName: store.user.name,
                        },
                        false,
                    );

                    store.app.typing.current = true;
                }

                clearTimeout(store.app.typing.timer);
                clearTimeout(store.app.typing.chatTimer);

                store.app.typing.timer = setTimeout(async () => {
                    await statusService.update(
                        {
                            origin: store.session.origin,
                            online: true,
                            typing: false,
                            attended: store.status.attended,
                            userName: store.user.name,
                        },
                        false,
                    );

                    store.app.typing.current = false;
                }, 5000);

                store.app.typing.chatTimer = setTimeout(async () => {
                    const message = event.target.value;

                    store.socket.client.emit(
                        `${store.conversation._id}_@_typing`,
                        message,
                    );
                }, 300);
            }
        });

    const inputTypingBarForm = document.getElementById(
        'chat-typing-bar',
    );

    function handleForm(event) {
        event.preventDefault();
    }

    inputTypingBarForm.addEventListener('submit', handleForm);
}

export function createVoiceWebElements() {
    let voice = $_omb.hasGetUserMediaBrowser();

    if (
        store.bot.services &&
        store.bot.services.voice_web &&
        store.bot.services.voice_web.active &&
        voice
    ) {
        document.getElementById('send').style.display = 'none';
        document.getElementById(
            'chat-voice-web-controls',
        ).style.display = 'block';
    } else {
        document.getElementById('send').style.display = 'block';
        document.getElementById(
            'chat-voice-web-controls',
        ).style.display = 'none';
    }
}

export default {
    createHtmlContainers,
    cheateHtmlChat,
    setChatSizes,
    setChatEvents,
    createVoiceWebElements,
};
