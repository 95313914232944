const chatHtml = `<div id="chat-header">
<div id="chat-header-left">
    <a onclick="$_omb.minimizeChatWindow()">
        <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M6 19h12v2H6z"/><path d="M0 0h24v24H0V0z" fill="none"/></svg>
    </a>
    <img id="chat-bot-avatar" alt="Mini avatar chatbot" >
    <span id="bot-avatar-name"></span>
</div>
<a onclick="$_omb.expandMenu()" id="chat-header-right">
        <svg class="material-icons chat-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>
</a>
</div>

<div id="chat-modal">
<div id="chat-modal-content">
    <p id="chat-modal-text"></p>
    <div id="chat-modal-buttons">
        <a class="chat-privacy-policy-button" id="chat-modal-button-yes">
            <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>
        </a>
        <a class="chat-privacy-policy-button" id="chat-modal-button-no" onclick="$_omb.collapseForgetUserDataMenu()">
            <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
        </a>
    </div>
    <div id="chat-modal-languages"></div>
</div>
</div>

<div id="chat-content">
<div id="chat-dropdown">
    <a id="chat-dropdown-policy-url" target="_blank" rel="noopener noreferrer" translate="politicaDePrivacidad">Pol&iacute;tica de privacidad</a>
    <!-- <a onclick="$_omb.openLanguageModal()" id="chat-dropdown-policy-url" target="_blank" rel="noopener noreferrer" translate="seleccionarIdioma">Seleccionar Idioma</a> -->
    <a onclick="$_omb.expandForgetUserDataMenu()" translate="olvidarMisDatos">Olvidar mis datos</a>
    <!-- <a href="https://1millionbot.com/" target="_blank" rel="noopener noreferrer" translate="sobreLosCreadores">Sobre los creadores</a> -->
</div>

<div id="chat-loading-screen" style="display: none;">
    <span>POWERED BY</span>
    <?xml version="1.0" encoding="utf-8"?>
    <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 588.4 205" style="enable-background:new 0 0 588.4 205;" xml:space="preserve">
        <style type="text/css">
        .st0{fill:url(#XMLID_5_);}
        .st1{fill:url(#XMLID_21_);}
        .st2{fill:#FFFFFF;}
        </style>
        <g id="Capa_2">
        </g>
        <g id="Capa_1">
        <g id="XMLID_822_">
            <g id="XMLID_1156_">
                <linearGradient id="XMLID_5_" gradientUnits="userSpaceOnUse" x1="492.1962" y1="173.1869" x2="492.2003" y2="173.1869">
                    <stop  offset="1.970443e-002" style="stop-color:#F86F60"/>
                    <stop  offset="0.668" style="stop-color:#E34D3E"/>
                    <stop  offset="1" style="stop-color:#D73A2B"/>
                </linearGradient>
                <path id="XMLID_1158_" class="st0" d="M492.2,173.2L492.2,173.2L492.2,173.2z"/>
                <linearGradient id="XMLID_21_" gradientUnits="userSpaceOnUse" x1="371.0489" y1="108.3514" x2="543.3246" y2="108.3514">
                    <stop  offset="1.970443e-002" style="stop-color:#FF6666"/>
                    <stop  offset="0.1954" style="stop-color:#ED5454"/>
                    <stop  offset="0.4495" style="stop-color:#DB4242"/>
                    <stop  offset="0.7134" style="stop-color:#D03737"/>
                    <stop  offset="1" style="stop-color:#CC3333"/>
                </linearGradient>
                <path id="XMLID_1157_" class="st1" d="M492.8,173.1c29-6.8,50.5-32.8,50.5-63.8V84c0-22.4-18.1-40.5-40.5-40.5h-91.3
                    C389.2,43.5,371,61.6,371,84v25.2c0,22.4,18.1,40.5,40.5,40.5h91.3c0,8.7-4.4,16.3-11.1,20.8c-0.1,0.1-0.3,0.2-0.4,0.3
                    c-0.3,0.2-0.4,0.6-0.4,1c0,0.7,0.6,1.4,1.4,1.4L492.8,173.1z"/>
            </g>
            <g id="XMLID_38_">
                <g id="XMLID_175_">
                    <path id="XMLID_181_" class="st2" d="M427.1,85.3c2.6,1.6,4.7,3.9,6.2,6.8c1.5,2.9,2.3,6.1,2.3,9.6s-0.8,6.7-2.4,9.6
                        c-1.6,2.9-3.9,5.1-6.8,6.8c-2.9,1.7-6,2.5-9.5,2.5s-6.6-0.8-9.5-2.5c-2.8-1.7-5.1-3.9-6.7-6.8c-1.7-2.9-2.5-6.1-2.5-9.6V70.7
                        c0-1,0.3-1.9,1-2.5c0.7-0.6,1.5-0.9,2.5-0.9c1,0,1.9,0.3,2.5,0.9c0.6,0.6,1,1.5,1,2.5v18.3c1.6-1.9,3.5-3.5,5.8-4.5
                        c2.3-1.1,4.7-1.6,7.3-1.6C421.5,82.9,424.5,83.7,427.1,85.3z M423.1,112.8c1.8-1.1,3.3-2.6,4.3-4.6c1.1-1.9,1.6-4.1,1.6-6.5
                        c0-2.4-0.5-4.6-1.6-6.5c-1.1-1.9-2.5-3.4-4.3-4.5c-1.8-1.1-3.9-1.7-6.1-1.7s-4.3,0.6-6.1,1.7c-1.8,1.1-3.3,2.6-4.3,4.5
                        c-1,1.9-1.6,4.1-1.6,6.5c0,2.4,0.5,4.6,1.6,6.5c1,1.9,2.5,3.5,4.3,4.6c1.8,1.1,3.9,1.7,6.1,1.7S421.2,113.9,423.1,112.8z"/>
                    <path id="XMLID_178_" class="st2" d="M457.4,118.1c-2.9-1.6-5.1-3.8-6.6-6.7c-1.6-2.8-2.4-6.1-2.4-9.7c0-3.7,0.8-6.9,2.4-9.8
                        c1.6-2.9,3.8-5.1,6.6-6.7c2.8-1.6,6.1-2.4,9.7-2.4c3.6,0,6.8,0.8,9.6,2.4c2.8,1.6,5,3.8,6.6,6.7c1.6,2.8,2.4,6.1,2.4,9.8
                        c0,3.6-0.8,6.9-2.4,9.7c-1.6,2.8-3.8,5.1-6.6,6.7c-2.8,1.6-6,2.4-9.7,2.4S460.3,119.7,457.4,118.1z M473.3,112.8
                        c1.8-1.1,3.3-2.6,4.3-4.5c1-1.9,1.5-4.1,1.5-6.5c0-2.4-0.5-4.6-1.5-6.6c-1-1.9-2.4-3.5-4.3-4.5c-1.8-1.1-3.9-1.6-6.2-1.6
                        c-2.3,0-4.4,0.5-6.2,1.6c-1.8,1.1-3.3,2.6-4.3,4.5c-1,1.9-1.6,4.1-1.6,6.6c0,2.4,0.5,4.6,1.6,6.5c1,1.9,2.5,3.4,4.3,4.5
                        c1.8,1.1,3.9,1.6,6.2,1.6C469.4,114.4,471.5,113.9,473.3,112.8z"/>
                    <path id="XMLID_176_" class="st2" d="M519.6,114.4c0.6,0.6,0.8,1.4,0.8,2.4c0,1-0.4,1.8-1.1,2.4c-0.7,0.6-1.7,0.9-2.9,0.9h-1.2
                        c-2.2,0-4.2-0.6-6.1-1.7c-1.8-1.2-3.3-2.7-4.3-4.7c-1-2-1.6-4.2-1.6-6.7V90.3h-3.1c-1,0-1.7-0.3-2.3-0.8c-0.6-0.5-0.9-1.2-0.9-2
                        c0-0.9,0.3-1.6,0.9-2.2c0.6-0.5,1.4-0.8,2.3-0.8h3.1v-9.8c0-1,0.3-1.8,1-2.4c0.6-0.6,1.4-0.9,2.4-0.9s1.8,0.3,2.4,0.9
                        c0.6,0.6,0.9,1.4,0.9,2.4v9.8h5.8c1,0,1.7,0.3,2.3,0.8c0.6,0.5,0.9,1.3,0.9,2.2c0,0.8-0.3,1.5-0.9,2s-1.4,0.8-2.3,0.8h-5.8v16.8
                        c0,1.8,0.5,3.3,1.5,4.5c1,1.2,2.2,1.8,3.7,1.8h2.3C518.4,113.5,519,113.8,519.6,114.4z"/>
                </g>
            </g>
            <g id="XMLID_3_">
                <g id="XMLID_253_">
                    <path id="XMLID_271_" d="M58,119.6c-0.6-0.7-0.9-1.4-0.9-2.4V77l-4.6,2c-0.7,0.3-1.4,0.5-2,0.5c-0.9,0-1.7-0.3-2.3-0.9
                        c-0.6-0.6-0.9-1.4-0.9-2.4c0-0.7,0.2-1.4,0.5-1.9c0.3-0.5,0.9-1,1.6-1.2l9.8-3.7c0.5-0.2,0.9-0.3,1.2-0.3c0.9,0,1.7,0.3,2.3,1
                        c0.6,0.6,0.9,1.5,0.9,2.5v44.6c0,0.9-0.3,1.7-0.9,2.4c-0.6,0.7-1.4,1-2.3,1C59.3,120.6,58.6,120.2,58,119.6z"/>
                    <path id="XMLID_269_" d="M133.1,88c2.3,2.5,3.4,6,3.4,10.5v18.8c0,1-0.3,1.8-0.9,2.4c-0.6,0.6-1.4,1-2.3,1c-0.9,0-1.7-0.3-2.3-1
                        c-0.6-0.6-0.9-1.4-0.9-2.4V98.4c0-2.7-0.6-4.7-1.9-6.1c-1.3-1.4-3-2.1-5.1-2.1c-2.2,0-4,0.8-5.4,2.4c-1.4,1.6-2.1,3.6-2.1,6.1
                        v18.6c0,1-0.3,1.8-0.9,2.4c-0.6,0.6-1.4,1-2.3,1c-0.9,0-1.7-0.3-2.3-1c-0.6-0.6-0.9-1.4-0.9-2.4V98.4c0-2.7-0.6-4.7-1.9-6.1
                        c-1.3-1.4-3-2.1-5.1-2.1c-2.2,0-4,0.8-5.5,2.4c-1.4,1.6-2.1,3.6-2.1,6.1v18.6c0,1-0.3,1.8-0.9,2.4c-0.6,0.6-1.4,1-2.3,1
                        c-0.9,0-1.7-0.3-2.3-1c-0.6-0.6-0.9-1.4-0.9-2.4V88c0-1,0.3-1.8,0.9-2.4c0.6-0.6,1.4-0.9,2.3-0.9c0.9,0,1.7,0.3,2.3,0.9
                        c0.6,0.6,0.9,1.4,0.9,2.3c1.1-1.1,2.4-2,3.9-2.7c1.5-0.6,3.2-1,4.9-1c2.1,0,4.1,0.5,5.8,1.3c1.7,0.9,3.1,2.2,4.2,3.9
                        c1.3-1.6,2.8-2.9,4.7-3.8s4-1.4,6.3-1.4C127.9,84.2,130.8,85.5,133.1,88z"/>
                    <path id="XMLID_266_" d="M155.4,77.4c-0.8-0.9-1.2-1.9-1.2-3.1c0-1.2,0.4-2.2,1.2-3.1c0.8-0.9,1.8-1.3,2.9-1.3s2.1,0.4,2.9,1.3
                        c0.8,0.9,1.2,1.9,1.2,3.1c0,1.2-0.4,2.2-1.2,3.1c-0.8,0.9-1.8,1.3-2.9,1.3S156.2,78.3,155.4,77.4z M156,119.6
                        c-0.6-0.6-0.9-1.4-0.9-2.4V87.9c0-1,0.3-1.8,0.9-2.4c0.6-0.6,1.4-0.9,2.3-0.9c1,0,1.7,0.3,2.3,0.9c0.6,0.6,0.9,1.4,0.9,2.4v29.3
                        c0,1-0.3,1.8-0.9,2.4c-0.6,0.6-1.4,1-2.3,1C157.4,120.6,156.6,120.2,156,119.6z"/>
                    <path id="XMLID_264_" d="M185.4,119c-1.5-1-2.6-2.5-3.4-4.3c-0.8-1.9-1.2-4-1.2-6.3V72.4c0-1,0.3-1.8,0.9-2.4
                        c0.6-0.6,1.3-0.9,2.3-0.9c0.9,0,1.7,0.3,2.3,0.9c0.6,0.6,0.9,1.4,0.9,2.4v35.9c0,1.6,0.3,3,0.9,4c0.6,1.1,1.3,1.6,2.3,1.6h1.6
                        c0.8,0,1.5,0.3,2.1,0.9c0.5,0.6,0.8,1.4,0.8,2.4c0,1-0.4,1.8-1.2,2.4c-0.8,0.6-1.8,0.9-3.1,0.9h-0.1
                        C188.5,120.6,186.9,120,185.4,119z"/>
                    <path id="XMLID_262_" d="M212.9,119c-1.5-1-2.6-2.5-3.4-4.3c-0.8-1.9-1.2-4-1.2-6.3V72.4c0-1,0.3-1.8,0.9-2.4
                        c0.6-0.6,1.3-0.9,2.3-0.9c0.9,0,1.7,0.3,2.3,0.9c0.6,0.6,0.9,1.4,0.9,2.4v35.9c0,1.6,0.3,3,0.9,4c0.6,1.1,1.3,1.6,2.3,1.6h1.6
                        c0.8,0,1.5,0.3,2.1,0.9c0.5,0.6,0.8,1.4,0.8,2.4c0,1-0.4,1.8-1.2,2.4c-0.8,0.6-1.8,0.9-3.1,0.9h-0.1
                        C216,120.6,214.4,120,212.9,119z"/>
                    <path id="XMLID_259_" d="M238,77.4c-0.8-0.9-1.2-1.9-1.2-3.1c0-1.2,0.4-2.2,1.2-3.1c0.8-0.9,1.8-1.3,2.9-1.3s2.1,0.4,2.9,1.3
                        c0.8,0.9,1.2,1.9,1.2,3.1c0,1.2-0.4,2.2-1.2,3.1c-0.8,0.9-1.8,1.3-2.9,1.3S238.8,78.3,238,77.4z M238.7,119.6
                        c-0.6-0.6-0.9-1.4-0.9-2.4V87.9c0-1,0.3-1.8,0.9-2.4c0.6-0.6,1.4-0.9,2.3-0.9c1,0,1.7,0.3,2.3,0.9c0.6,0.6,0.9,1.4,0.9,2.4v29.3
                        c0,1-0.3,1.8-0.9,2.4c-0.6,0.6-1.4,1-2.3,1C240,120.6,239.3,120.2,238.7,119.6z"/>
                    <path id="XMLID_256_" d="M268.9,118.5c-2.6-1.6-4.7-3.7-6.2-6.5c-1.5-2.8-2.2-5.9-2.2-9.4c0-3.6,0.7-6.7,2.2-9.5
                        c1.5-2.8,3.5-4.9,6.2-6.5c2.7-1.6,5.7-2.3,9-2.3c3.3,0,6.3,0.8,8.9,2.3c2.6,1.6,4.7,3.7,6.2,6.5c1.5,2.8,2.2,5.9,2.2,9.5
                        c0,3.5-0.7,6.6-2.2,9.4c-1.5,2.8-3.5,4.9-6.2,6.5c-2.6,1.6-5.6,2.3-9,2.3C274.5,120.8,271.5,120,268.9,118.5z M283.7,113.3
                        c1.7-1.1,3-2.5,4-4.4c0.9-1.9,1.4-4,1.4-6.4c0-2.4-0.5-4.5-1.4-6.4c-0.9-1.9-2.3-3.4-4-4.4c-1.7-1.1-3.6-1.6-5.8-1.6
                        s-4.1,0.5-5.8,1.6s-3,2.5-4,4.4c-1,1.9-1.5,4-1.5,6.4c0,2.4,0.5,4.5,1.5,6.4c1,1.9,2.3,3.3,4,4.4s3.6,1.6,5.8,1.6
                        S281.9,114.4,283.7,113.3z"/>
                    <path id="XMLID_254_" d="M336.4,86.2c2.3,1.3,4.1,3.2,5.4,5.8c1.3,2.5,2,5.6,2,9.1v16.2c0,1-0.3,1.8-0.9,2.4
                        c-0.6,0.6-1.4,1-2.3,1c-0.9,0-1.7-0.3-2.3-1c-0.6-0.6-0.9-1.4-0.9-2.4v-16.2c0-3.6-0.9-6.2-2.7-8.1c-1.8-1.8-4.3-2.7-7.3-2.7
                        c-1.8,0-3.4,0.4-4.8,1.1c-1.5,0.7-2.6,1.8-3.4,3c-0.8,1.3-1.2,2.7-1.2,4.3v18.6c0,1-0.3,1.8-0.9,2.4c-0.6,0.6-1.4,1-2.3,1
                        c-0.9,0-1.7-0.3-2.3-1c-0.6-0.6-0.9-1.4-0.9-2.4V87.9c0-1,0.3-1.8,0.9-2.4c0.6-0.6,1.4-0.9,2.3-0.9c1,0,1.7,0.3,2.3,0.9
                        c0.6,0.6,0.9,1.4,0.9,2.4v0.7c1.3-1.4,2.9-2.5,4.8-3.3c1.9-0.8,3.8-1.2,5.9-1.2C331.5,84.2,334.1,84.9,336.4,86.2z"/>
                </g>
            </g>
        </g>
        </g>
        </svg>

    <div class="lds-ring" id="chat-loading-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<div id="chat-privacy-policy-screen">
    <p id="chat-privacy-policy-text"></p>
    <a id="chat-privacy-policy-url" class="custom-underline" target="_blank" rel="noopener noreferrer" translate="politicadePrivacidadMayus">POL&Iacute;TICA DE PRIVACIDAD</a>
    <p id="chat-privacy-policy-lecture"></p>
    <div id="chat-privacy-policy-buttons">
        <a class="chat-privacy-policy-button" id="chat-privacy-policy-button-yes" onclick="$_omb.acceptPolicies()">
            <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>
        </a>
        <a class="chat-privacy-policy-button" id="chat-privacy-policy-button-no" onclick="$_omb.minimizeChatWindow()">
            <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
        </a>
    </div>
</div>


<div id="chat-body">
    <!-- <ul id="chat-messages" tabindex="0"></ul> -->

    <div id="chat-buttons-response"></div>
    <form id="chat-typing-bar" tabindex="0" onsubmit="$_omb.addUserMessage()">
        <input type="text" id="typing-bar" title="Escribir mensaje" placeholder="Escribe tu pregunta" maxlength="250" autocomplete="off">
        <svg id="send-background" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="70px" height="51px" viewBox="0 0 70 51" style="enable-background:new 0 0 70 51;" xml:space="preserve">
            <style type="text/css">
                #st0{fill:#330000;}
            </style>
        <defs>
        </defs>
        <path id="st0" d="M0,51c0,0,5.7-3.7,13.4-25.7C21.2,3.2,28.2,0,41.4,0S70,0,70,0v51H0z"/>
        </svg>
        <button type="submit" form="chat-typing-bar" type="reset" id="send">
            <svg class="material-icons chat-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
        </button>
        <div id="chat-voice-web-controls">
            <button onclick="$_omb.recordAudioWebRTC()" id="chat-micro">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="white" d="M11.999 14.942c2.001 0 3.531-1.53 3.531-3.531V4.35c0-2.001-1.53-3.531-3.531-3.531S8.469 2.35 8.469 4.35v7.061c0 2.001 1.53 3.531 3.53 3.531zm6.238-3.53c0 3.531-2.942 6.002-6.237 6.002s-6.237-2.471-6.237-6.002H3.761c0 4.001 3.178 7.297 7.061 7.885v3.884h2.354v-3.884c3.884-.588 7.061-3.884 7.061-7.885h-2z"></path></svg>
            </button>
            <button onclick="$_omb.cancelAudioRecord()" id="chat-cancel-voice">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="24" height="24"><path fill="white" d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path></svg>
            </button>
            <div id="chat-counter-voice">
            </div>
            <button onclick="$_omb.sendAudioRecord()" id="chat-send-voice">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="24" height="24"><path fill="white" d="M10.219,1.688c-4.471,0-8.094,3.623-8.094,8.094s3.623,8.094,8.094,8.094s8.094-3.623,8.094-8.094S14.689,1.688,10.219,1.688 M10.219,17.022c-3.994,0-7.242-3.247-7.242-7.241c0-3.994,3.248-7.242,7.242-7.242c3.994,0,7.241,3.248,7.241,7.242C17.46,13.775,14.213,17.022,10.219,17.022 M15.099,7.03c-0.167-0.167-0.438-0.167-0.604,0.002L9.062,12.48l-2.269-2.277c-0.166-0.167-0.437-0.167-0.603,0c-0.166,0.166-0.168,0.437-0.002,0.603l2.573,2.578c0.079,0.08,0.188,0.125,0.3,0.125s0.222-0.045,0.303-0.125l5.736-5.751C15.268,7.466,15.265,7.196,15.099,7.03"></path></svg>
            </button>
            <div id="chat-audio-content-voice">
                <audio id="chat-audio-voice"></audio>
            </div>
        </div>
    </form>
    <div id="chat-footer">
        <a href="https://1millionbot.com/" target="_blank" rel="noopener noreferrer">
            by 1millionbot
        </a>
    </div>
</div>
</div>
<button id="chat-scroll-arrow" onclick="$_omb.scrollToBottom()" class="material-icons chat-scroll-arrow">
    <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/><path d="M0 0h24v24H0V0z" fill="none"/></svg>
</button>
`;

export default chatHtml;
