'use strict';

import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider';
import xss from 'xss';

import { store } from '../store';

import translations from '../data/i18n';

import messageService from '../services/message';
import statusService from '../services/status';
import audio from './audio';
import notificationService from '../services/notification';
import answerService from '../services/answer';

export function addResponseMessage(message, messageId = null) {
    const messageHasText = message && message.text;
    const messageHasPayload = message && message.payload;
    const messageHasCards =
        messageHasPayload && message.payload.cards;
    const messageHasImages =
        messageHasPayload && message.payload.images;
    const messageHasVideos =
        messageHasPayload && message.payload.videos;
    const messageHasButtons =
        messageHasPayload && message.payload.buttons;
    const messageHasForm = messageHasPayload && message.payload.form;
    const messageHasSurvey =
        messageHasPayload && message.payload.survey;

    const firstMessageClass = 'chat-message-bot-first';
    const messageClass = 'chat-message-bot';

    if (messageHasText) {
        $_omb.printText(
            message.text,
            messageHasImages || messageHasVideos
                ? messageClass
                : firstMessageClass,
        );
    }

    if (messageHasCards) {
        $_omb.printCards(message.payload.cards, messageClass);
    }

    if (messageHasImages) {
        $_omb.printImages(message.payload.images, messageClass);
    }

    if (messageHasVideos) {
        $_omb.printVideos(message.payload.videos, messageClass);
    }

    if (messageHasButtons) {
        $_omb.printButtons(message.payload.buttons);
    }

    if (messageHasForm) {
        $_omb.printForm(message.payload.form);
    }

    if (messageHasSurvey) {
        $_omb.printSurveyQuestion(
            {
                ...message,
                _id:
                    messageId ||
                    store.conversation.messages[
                        store.conversation.messages.length - 1
                    ]._id,
            },
            messageClass,
        );
    }

    $_omb.removeBotTypingLoader();
    $_omb.scrollUntilUserMessage();
    audio.checkAudioResponse(message);
}

export function printBotTypingLoader() {
    const chatMessages = $_omb.getChatMessagesContainer();
    const chatMessageTyping = document.querySelector(
        '#chat-message-typing',
    );

    if (!chatMessages.contains(chatMessageTyping)) {
        const bullet = document.createElement('li');

        bullet.id = 'chat-message-typing';
        bullet.innerText =
            translations[store.i18n.language]['escribiendo'];

        chatMessages.appendChild(bullet);
    } else {
        const typingLoader = chatMessages.removeChild(
            chatMessageTyping,
        );

        chatMessages.appendChild(typingLoader);
    }
}

export function removeBotTypingLoader() {
    const typingLoader = document.querySelector(
        '#chat-message-typing',
    );

    if (typingLoader) {
        typingLoader.remove();
    }
}

export const addUserMessage = async function(message = null) {
    const typingBar = document.querySelector('#typing-bar');
    const chatButtonsResponse = document.querySelector(
        '#chat-buttons-response',
    );
    const isChatButtonsResponseVisible =
        chatButtonsResponse.style.display !== 'none';

    let shouldPost = false;
    if (!message) {
        message = typingBar.value;
        shouldPost = true;
    }

    typingBar.focus();
    typingBar.value = '';

    handleVoiceInputText(typingBar.value.length);

    if (message !== '') {
        if (isChatButtonsResponseVisible) {
            $_omb.clearButtonsSection();
        }

        $_omb.printText(message, 'chat-message-user');

        if (shouldPost) {
            await messageService.create(message);
        }
    }
};

export async function loadCarouselProperties() {
    let navs = document.querySelectorAll(
        '.chat-message-bot > .tns-outer > .tns-nav',
    );

    let sliderCount = document.querySelectorAll(
        '.tiny-chat-slider-cards',
    ).length;
    let i = 1;
    if (sliderCount >= 2) {
        for (let sliderNav of navs) {
            sliderNav.remove();
            tns({
                container: `#tiny-chat-slider-${i}`,
                items: 1,
                navPosition: 'bottom',
                mouseDrag: true,
                slideBy: 'page',
                controls: false,
                loop: false,
                preventScrollOnTouch: false,
            });
            i++;
        }
    }
}

export async function handleButtonEvent(buttonEvent) {
    const button = buttonEvent.target;
    const showNotification = button.getAttribute('notification');
    const hasEvent = button.getAttribute('event') === 'true';
    const event = hasEvent ? button.value : null;

    let message = button.value;

    if (showNotification) {
        const notificationValue = button.getAttribute(
            'notificationValue',
        );
        const departmentName = button.value;
        message = xss(button.innerHTML);

        const {
            requestIntervention,
        } = await notificationService.create({
            bot: {
                id: store.bot._id,
                alias: store.alias._id,
                name: store.alias.name,
                image: store.alias.image,
            },
            user: {
                name: store.user.name,
                id: store.user._id,
            },
            conversation: {
                id: store.conversation._id,
            },
            department: departmentName,
            language: store.i18n.language,
            type: hasEvent ? 'event' : 'text',
            value: notificationValue,
        });

        await statusService.update(
            {
                origin: store.session.origin,
                online: true,
                typing: false,
                attended: store.status.attended,
                userName: store.user.name,
                requestIntervention: {
                    department: departmentName,
                    id: requestIntervention._id,
                },
            },
            true,
        );

        startQueueTimeout();
    } else {
        message = !hasEvent ? button.value : null;
    }

    $_omb.clearButtonsSection();

    if (!hasEvent || showNotification) {
        $_omb.printText(message, 'chat-message-user');
    }

    if (!showNotification) {
        await messageService.create(message, event);
    }
}

export async function handleSurveyEvent(buttonEvent, message) {
    const survey = message.payload.survey;
    const button = buttonEvent.target;
    const valueMessage = parseInt(button.value);
    const questionId = survey.question._id;
    const isNpsQuestion = survey.question.nps_question || false;
    const anwerType = survey.question.answer;
    const npsFormula = {
        stars: 2,
        emojis: 2,
    };

    const surveyAnswer = {
        company: store.bot.company,
        bot: store.bot._id,
        alias: store.alias.name,
        conversation: store.conversation._id,
        user: store.user._id,
        survey: survey._id,
        survey_goal: survey.goal,
        question: questionId,
        question_message: message._id,
        answer: valueMessage,
        integration: 'web',
        language: store.i18n.language,
        ...(isNpsQuestion
            ? { nps_score: npsFormula[anwerType] * valueMessage }
            : {}),
        ...(survey.employee && survey.employee._id
            ? {
                  attended_by_employee: survey.employee._id,
                  attended_by_department: survey.employee.department,
              }
            : {}),
    };

    await answerService.create(surveyAnswer);

    button.classList.add('selected');

    [...button.parentElement.children].forEach(button =>
        button.classList.add('disabled'),
    );

    $_omb.scrollToBottom();
}

function startQueueTimeout() {
    store.app.help.timer = setTimeout(async () => {
        if (
            store.status.attended &&
            !store.status.attended.employee
        ) {
            const message = store.alias.keep_waiting_message || '';

            $_omb.printText(message, 'chat-message-bot');
            $_omb.scrollToBottom();
            $_omb.startQueueTimeout();
        }
    }, 300000);
}

export function transformTagsToHTML(message) {
    const underscores = message.match(new RegExp(' _.*?_', 'igm'));
    const asterisk = message.match(new RegExp(' [*].*?[*]', 'igm'));

    let cleanText;
    let replaceItem;
    let textHTML = message;

    if (underscores) {
        underscores.map(function(item) {
            cleanText = item.substring(1, item.length - 1);
            replaceItem = `<i>${cleanText}</i>`;
            textHTML = textHTML.replace(item, replaceItem);
        });
    }

    if (asterisk) {
        asterisk.map(function(item) {
            cleanText = item.substring(1, item.length - 1);
            replaceItem = `<b>${cleanText}</b>`;
            textHTML = textHTML.replace(item, replaceItem);
        });
    }

    return textHTML;
}

export const handleEnterKey = function(event) {
    if (event.key === 'Enter') {
        $_omb.addUserMessage();
    }
};

export const handleVoiceInputText = function(countLetters) {
    const botHasVoiceService =
        store.bot.services &&
        store.bot.services.voice_web &&
        store.bot.services.voice_web.active;

    if (botHasVoiceService) {
        const sendElement = document.querySelector('#send');
        const chatMicro = document.querySelector('#chat-micro');

        if (countLetters > 0) {
            sendElement.style.display = 'block';
            chatMicro.style.display = 'none';
        } else {
            sendElement.style.display = 'none';
            chatMicro.style.display = 'block';
        }
    }
};

export function scrollUntilUserMessage() {
    const scrollTopMessageUser = store.app.lastMessageUserScroll;

    const messagesContainer = document.querySelector(
        '#chat-messages',
    );

    // userMessage + height of window = userMessage remains at top
    messagesContainer.scrollTop = `${scrollTopMessageUser +
        0.8 * messagesContainer.clientHeight}`;
}

export default {
    addUserMessage,
    addResponseMessage,
    transformTagsToHTML,
    handleEnterKey,
    handleVoiceInputText,
    printBotTypingLoader,
    removeBotTypingLoader,
    scrollUntilUserMessage,
    loadCarouselProperties,
    handleButtonEvent,
    handleSurveyEvent,
};
