'use strict';

import { store } from '../store';
import utils from '../utils';

export async function get() {
    const currentUserId = utils.getCookie(
        `$omb_user_${store.bot._id}`,
    );

    if (currentUserId) {
        store.user._id = currentUserId;

        const baseURL = `${store.api.url}/public/users/${currentUserId}`;
        const fetchOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `API-KEY ${store.api.key}`,
            },
        };

        fetch(baseURL, fetchOptions).then(userData => {
           if (userData.status === 200) {
                userData.json().then(data => {
                    store.user = data.user;
                });
            }
        });
    }
}

export async function create() {
    await getUserGeolocation();

    const baseURL = `${store.api.url}/public/users`;
    const fetchOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `API-KEY ${store.api.key}`,
        },
        body: JSON.stringify({
            language: navigator.language,
            platform: navigator.platform,
            country: store.geolocation.country.name,
            timezone: store.geolocation.timezone.name,
            ip: store.geolocation.asn.ip,
            bot: store.bot._id,
        }),
    };

    if (store.socket.client) {
        store.socket.client.disconnect();
    }

    const { user } = await (
        await fetch(baseURL, fetchOptions)
    ).json();

    if (user) {
        store.user = user;
        utils.deleteCookie(`$omb_user_${store.bot._id}`);
        utils.setCookie(`$omb_user_${store.bot._id}`, user._id);
    }
}

async function getUserGeolocation() {
    if (!store.geolocation.asn.ip) {
        const baseURL = 'https://api.whoseipisthis.com';

        const fetchOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const geolocationData = await (
            await fetch(baseURL, fetchOptions)
        ).json();

        if (geolocationData.asn && geolocationData.asn.ip) {
            store.geolocation = geolocationData;
        }
    }
}

export default {
    get,
    create,
};
