'use strict';

import { store } from '../store';

export async function create(notification) {
    const baseURL = `${store.api.url}/public/live/notifications`;
    const fetchOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `API-KEY ${store.api.key}`,
        },
        body: JSON.stringify(notification),
    };

    const notificationResponse = await (
        await fetch(baseURL, fetchOptions)
    ).json();

    return notificationResponse.data;
}

export default {
    create,
};
