'use strict';

import { store } from './store';

import handlers from './handlers';
import controllers from './controllers';

import statusService from './services/status';
import socketService from './services/socket';

if (
    process.env.NODE_ENV !== 'production' &&
    process.env.NODE_ENV !== 'staging'
) {
    controllers.store = store;
}

window.$omb = controllers;
global.$omb = controllers;

window.$_omb = { ...handlers };
global.$_omb = { ...handlers };

const insecureInit = async () => {
    if (!store.api.key) {
        await controllers.prepareInsecureInit();

        await controllers.init({
            key: store.api.key,
        });
    }
};

window.addEventListener('beforeunload', function() {
    socketService.kill(async () => {
        await statusService.update(
            {
                origin: store.session.origin,
                online: false,
                typing: false,
                attended: store.status.attended,
                userName: store.user.name,
            },
            true,
        );
    });
});

window.addEventListener('unload', function() {
    socketService.kill(async () => {
        await statusService.update(
            {
                origin: store.session.origin,
                online: false,
                typing: false,
                attended: store.status.attended,
                userName: store.user.name,
            },
            true,
        );
    });
});

document.addEventListener('readystatechange', () => {
    if (
        document.readyState === 'complete' ||
        (document.readyState !== 'loading' &&
            !document.documentElement.doScroll)
    ) {
        insecureInit();
    } else {
        document.addEventListener('DOMContentLoaded', insecureInit);
    }
});

export default controllers;
