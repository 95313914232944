'use strict';

import chat from './chat';
import menu from './menu';
import languages from './languages';
import views from './views';
import messages from './messages';
import audio from './audio';
import formatters from './formatters';
import custom from './custom';

export default {
    ...chat,
    ...menu,
    ...languages,
    ...views,
    ...messages,
    ...audio,
    ...formatters,
    ...custom,
};
