'use strict';

import { store } from '../store';
import { setCookie } from '../utils';

// import userService from '../services/user.js';

export const openChatWindow = async function() {
    const chatModal = document.querySelector('#chat-modal');
    const avatarContainer = document.querySelector(
        '#avatar-container',
    );
    const chatDropdown = document.querySelector('#chat-dropdown');
    const botContainer = document.querySelector('#bot-container');
    const callToActionBubble = document.querySelector(
        '#call-to-action-bubble',
    );
    const closeBubbleButton = document.querySelector('#close-bubble');

    avatarContainer.style.display = 'none';
    chatModal.style.display = 'none';
    chatDropdown.style.display = 'none';
    botContainer.style.display = 'flex';
    botContainer.classList.add('scale-in-br');
    botContainer.classList.remove('scale-out-br');

    if (callToActionBubble) {
        callToActionBubble.style.display = 'none';
    }

    if (closeBubbleButton) {
        closeBubbleButton.style.display = 'none';
    }

    $_omb.doTraslation();
    await $_omb.showMainView();
};

export function closeChatWindow() {
    const chatBody = document.querySelector('#chat-body');
    const chatMessages = $_omb.getChatMessagesContainer();
    const chatButtonsResponse = document.querySelector(
        '#chat-buttons-response',
    );
    const callToActionBubble = document.querySelector(
        '#call-to-action-bubble',
    );
    const isChatButtonsResponseVisible =
        chatButtonsResponse.style.display !== 'none';
    // localStorage.removeItem('chatStored_' + store.bot._id);

    while (chatMessages.firstChild) {
        chatMessages.removeChild(chatMessages.firstChild);
    }

    if (!isChatButtonsResponseVisible) {
        clearButtonsSection();
    }

    removeScrollArrow();
    minimizeChatWindow();

    if (callToActionBubble) {
        callToActionBubble.style.display = 'block';
    }

    chatBody.removeEventListener('click', $_omb.expandMenu);
}

export function clearButtonsSection() {
    const chatButtonResponse = document.querySelector(
        '#chat-buttons-response',
    );
    const isChatButtonResponseVisible =
        chatButtonResponse.style.display !== 'none';

    if (isChatButtonResponseVisible) {
        const buttonsSection = document.querySelector(
            '#chat-buttons-response',
        );
        const chatMessages = $_omb.getChatMessagesContainer();

        buttonsSection.classList.remove('swing-in-bottom-fwd');
        buttonsSection.classList.add('swing-out-bottom-bck');

        while (buttonsSection.firstChild) {
            buttonsSection.removeChild(buttonsSection.firstChild);
        }

        buttonsSection.style.display = 'none';
        chatMessages.style.height = '380px';
    }
}

export function removeScrollArrow() {
    const chatScrollArrow = document.querySelector(
        '#chat-scroll-arrow',
    );

    chatScrollArrow.style.display = 'none';
}

export function showScrollArrow() {
    const chatScrollArrow = document.querySelector(
        '#chat-scroll-arrow',
    );
    const chatButtonResponse = document.querySelector(
        '#chat-buttons-response',
    );
    const typingBar = document.querySelector('#chat-typing-bar');
    const chatFooter = document.querySelector('#chat-footer');

    const arrowPosition =
        typingBar.clientHeight + chatFooter.clientHeight + 16;

    if (chatButtonResponse.style.display == 'block') {
        chatScrollArrow.style.bottom =
            arrowPosition + chatButtonResponse.clientHeight + 'px';
    } else {
        chatScrollArrow.style.bottom = arrowPosition + 'px';
    }

    chatScrollArrow.style.display = 'block';
}

export const hideAvatar = function() {
    const avatarMinimizedContainer = document.querySelector(
        '#avatar-minimized-container',
    );

    avatarMinimizedContainer.classList.add('fade-out-right');
};

export const acceptPolicies = async function() {
    const shouldInitLanguage = true;
    setCookie(`$omb_agreed_policies_${store.bot._id}`, true);

    if (store.i18n.languages.length > 1) {
        const policyScreen = document.querySelector(
            '#chat-privacy-policy-screen',
        );
        const chatBody = document.querySelector('#chat-body');
        const botHasLanguageMenu =
            store.bot.services &&
            store.bot.services.lang_menu &&
            store.bot.services.lang_menu.active;

        policyScreen.style.display = 'none';
        chatBody.style.display = 'flex';

        if (botHasLanguageMenu) {
            await $_omb.showMainView();
        } else {
            $_omb.openLanguageModal(shouldInitLanguage);
        }
    } else {
        await $_omb.showMainView();
    }
};

export const minimizeChatWindow = function() {
    const avatarContainer = document.querySelector(
        '#avatar-container',
    );
    const closeBubbleButton = document.querySelector('#close-bubble');
    const botContainer = document.querySelector('#bot-container');

    avatarContainer.style.display = 'block';
    closeBubbleButton.style.display = 'block';
    botContainer.classList.add('scale-out-br');
    botContainer.classList.remove('scale-in-br');
};

export const scrollToBottom = function() {
    const chatMessages = $_omb.getChatMessagesContainer();
    const chatMessagesHeight = chatMessages.scrollHeight;

    chatMessages.scrollTop = chatMessagesHeight;
    $_omb.removeScrollArrow();
};

export function getChatMessagesContainer() {
    if (!document.querySelector('#chat-messages')) {
        const chatMessages = document.createElement('ul');
        chatMessages.id = 'chat-messages';
        chatMessages.tabIndex = 0;
        document.querySelector('#chat-body').prepend(chatMessages);

        document
            .querySelector('#chat-messages')
            .addEventListener('scroll', function() {
                let chatMessages = document.getElementById(
                    'chat-messages',
                );
                let scrolledVertically = chatMessages.scrollTop;
                let fullChatHeight = chatMessages.scrollHeight;
                let viewableChatHeight = chatMessages.clientHeight;

                if (
                    fullChatHeight - parseInt(scrolledVertically) >
                    viewableChatHeight * 1.2
                ) {
                    $_omb.showScrollArrow();
                } else {
                    $_omb.removeScrollArrow();
                }
            });
    }

    return document.querySelector('#chat-messages');
}

export default {
    acceptPolicies,
    openChatWindow,
    closeChatWindow,
    hideAvatar,
    minimizeChatWindow,
    scrollToBottom,
    removeScrollArrow,
    showScrollArrow,
    clearButtonsSection,
    getChatMessagesContainer,
};
