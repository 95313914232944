'use strict';

import io from 'socket.io-client';

import { store } from '../store';

export function create(onConnectMethod) {
    store.socket.client = io(store.socket.url, {
        transports: ['websocket', 'polling'],
        forceNew: true,
        query: {
            bot: store.bot._id,
            conversation: store.conversation._id,
        },
    });

    store.socket.client.on('connect', async function() {
        await onConnectMethod();
    });

    store.socket.client.on(`${store.conversation._id}`, function(
        data,
    ) {
        if (data.type !== 'User') {
            $_omb.addResponseMessage(
                data.message,
                data.messageId || null,
            );
        } else if (data.type === 'User' && !document.hasFocus()) {
            $_omb.addUserMessage(data.message.text);
        }
    });

    store.socket.client.on(
        `${store.conversation._id}_@_status`,
        async function(data) {
            if (
                data.attended &&
                data.attended.employee &&
                !store.status.attended.employee
            ) {
                store.status.attended = data.attended;
                $_omb.loadAgentProperties();
            }

            if (
                data.attended &&
                !data.attended.employee &&
                store.status.attended.employee
            ) {
                store.status.attended = {};
                $_omb.loadBotProperties();
            }
        },
    );
}

export async function kill(beforeKillMethod) {
    await beforeKillMethod();

    store.socket.client.disconnect();
}

export default {
    create,
    kill,
};
