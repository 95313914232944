'use strict';

import { store } from './store';

export function checkApiKey() {
    if (!store.api.key) {
        throw Error('NO F KEY');
    }
}

export function getCookie(name) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');

    if (parts.length == 2) {
        return parts
            .pop()
            .split(';')
            .shift();
    }
}

export function setCookie(name, value) {
    const now = new Date();
    now.setTime(now.getTime() + 5 * 365 * 24 * 60 * 60 * 1000);

    document.cookie =
        name +
        '=' +
        value +
        ';' +
        'expires=' +
        now.toUTCString() +
        ';path=/';
}

export function deleteCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;path=/;';
}

export function hexToRgb(hex) {
    return hex
        .replace(
            /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
            (m, r, g, b) => '#' + r + r + g + g + b + b,
        )
        .substring(1)
        .match(/.{2}/g)
        .map(x => parseInt(x, 16));
}

export function loadCSSResource(url) {
    const style = document.createElement('link');

    style.rel = 'stylesheet';
    style.href = url;

    document.head.appendChild(style);
}

export function loadJavascriptResource(url, callback) {
    const script = document.createElement('script');

    script.src = url;
    script.type = 'text/javascript';
    script.onreadystatechange = callback;
    script.onload = callback;

    document.head.appendChild(script);
}

export default {
    checkApiKey,
    getCookie,
    setCookie,
    deleteCookie,
    hexToRgb,
    loadJavascriptResource,
    loadCSSResource,
};
