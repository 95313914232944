'use strict';

import xss from 'xss';

import { store } from '../store';
import utils from '../utils';

import translations from '../data/i18n';

import statusService from '../services/status.js';

export const expandMenu = function() {
    const chatDropdown = document.querySelector('#chat-dropdown');
    const chatBody = document.querySelector('#chat-body');
    const isChatDropdownVisible =
        chatDropdown.style.display !== 'none';

    if (!isChatDropdownVisible) {
        chatDropdown.style.display = 'flex';
        chatBody.addEventListener('click', collapseMenu);
    } else {
        chatDropdown.style.display = 'none';
        chatBody.removeEventListener('click', expandMenu);
    }
};

export const collapseMenu = function() {
    const chatDropdown = document.querySelector('#chat-dropdown');
    const isChatDropdownVisible =
        chatDropdown.style.display !== 'none';

    if (isChatDropdownVisible) {
        chatDropdown.style.display = 'none';
    }
};

export const collapseForgetUserDataMenu = function() {
    const chatModal = document.querySelector('#chat-modal');
    const isChatModalVisible = chatModal.style.display !== 'none';

    if (isChatModalVisible) {
        chatModal.style.display = 'none';

        expandMenu();
    }
};

export const expandForgetUserDataMenu = function() {
    const chatModal = document.querySelector('#chat-modal');
    const isChatModalVisible = chatModal.style.display !== 'none';

    if (!isChatModalVisible) {
        const chatModalLanguages = document.querySelector(
            '#chat-modal-languages',
        );
        const chatModalButtons = document.querySelector(
            '#chat-modal-buttons',
        );
        const chatModalText = document.querySelector(
            '#chat-modal-text',
        );
        const forgetButtonYes = document.querySelector(
            '#chat-modal-button-yes',
        );
        const forgetButtonNo = document.querySelector(
            '#chat-modal-button-no',
        );

        chatModal.style.display = 'block';
        chatModalLanguages.style.display = 'none';
        chatModalButtons.style.display = 'flex';

        chatModalText.innerHTML =
            translations[store.i18n.language][
                'procedimientoIrreversible'
            ];

        forgetButtonYes.addEventListener('click', forgetUserData);
        forgetButtonNo.addEventListener(
            'click',
            collapseForgetUserDataMenu,
        );
    }
};

export function loadBotProperties() {
    const botAvatarName = document.querySelector('#bot-avatar-name');
    const avatarMinimized = document.querySelector(
        '#avatar-minimized',
    );
    const chatBotAvatar = document.querySelector('#chat-bot-avatar');

    botAvatarName.innerHTML = xss(store.alias.name);
    avatarMinimized.src = store.alias.image;
    chatBotAvatar.src = store.alias.image;
}

export function loadCustomerProperties() {
    if (store.alias.styles) {
        const botContainer = document.getElementById('bot-container');
        const avatarContainer = document.getElementById(
            'avatar-container',
        );
        const properties = store.alias.styles;

        switch (properties.position) {
            case 'bottom-left':
                botContainer.style.right = 'auto';
                botContainer.style.bottom = 0;
                botContainer.style.left = 0;

                avatarContainer.style.right = 'auto';
                avatarContainer.style.bottom = '2vh';
                avatarContainer.style.left = '2vw';
                break;
            default:
                botContainer.style.right = 0;
                botContainer.style.bottom = 0;
                botContainer.style.left = 'auto';

                avatarContainer.style.right = '2vw';
                avatarContainer.style.bottom = '2vh';
                avatarContainer.style.left = 'auto';
                break;
        }

        const color = properties.primary_color
            ? properties.primary_color
            : '#696969';
        document.getElementById(
            'chat-header',
        ).style.backgroundColor = color;

        document.getElementById('st0').style.fill =
            store.alias.styles.primary_color;

        // Cards
        let indicators = document.getElementsByClassName(
            'indicators',
        );
        Array.from(indicators).forEach(function(element) {
            element.style.backgroundColor = color;
        });
        const customUnderline = document.createElement('style');
        customUnderline.type = 'text/css';
        customUnderline.innerHTML =
            ".custom-underline::after { content: ''; display: block;  width: 0; height: 2px; transition: width .2s; background: ";
        customUnderline.innerHTML += color;
        customUnderline.innerHTML +=
            '} .custom-underline:hover::after { width: 100%; }';
        document.head.appendChild(customUnderline);

        const rgb = utils.hexToRgb(color);
        document.getElementById('chat-header').style.color =
            rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114 > 176
                ? '#000000'
                : '#ffffff';
        document.getElementById('bot-avatar-name').style.color =
            rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114 > 176
                ? '#000000'
                : '#ffffff';
        document.getElementById('send').style.color =
            rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114 > 176
                ? '#000000'
                : '#ffffff';
        for (let element of document.getElementsByClassName(
            'chat-icon',
        )) {
            element.style.fill =
                rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114 > 176
                    ? '#000000'
                    : '#ffffff';
        }

        Array.from(
            document.getElementById('chat-loading-ring').childNodes,
        ).forEach(function(element) {
            if (element instanceof HTMLElement) {
                element.style.borderTopColor = color;
            }
        });

        Array.from(
            document.getElementById('chat-dropdown').childNodes,
        ).forEach(function(element) {
            if (element instanceof HTMLElement) {
                element.style.borderColor = color;
            }
        });

        let sansserif = ', sans-serif';
        switch (properties.font_family.toLowerCase()) {
            case 'roboto':
                utils.loadCSSResource(
                    'https://fonts.googleapis.com/css?family=Roboto:300,400,700',
                );
                break;
            case 'montserrat':
                utils.loadCSSResource(
                    'https://fonts.googleapis.com/css?family=Montserrat:300,400,700',
                );
                break;
            case 'lora':
                utils.loadCSSResource(
                    'https://fonts.googleapis.com/css?family=Lora:400,700',
                );
                sansserif = ', serif';
                break;
            default:
                utils.loadCSSResource(
                    'https://fonts.googleapis.com/css?family=Roboto:300,400,700',
                );
                break;
        }

        document.getElementById('bot-container').style.fontFamily =
            properties.font_family + sansserif;
        document.getElementById('avatar-container').style.fontFamily =
            properties.font_family + sansserif;

        for (let element of document
            .getElementById('bot-container')
            .getElementsByTagName('p')) {
            element.style.fontFamily =
                properties.font_family.toLowerCase() + sansserif;
        }
        if (document.getElementById('call-to-action-bubble')) {
            document.getElementById(
                'call-to-action-bubble',
            ).innerText = store.alias.call_to_action
                ? store.alias.call_to_action
                : 'Hola, &iquest;Puedo ayudarte?';
        }

        if (properties.hide_footer) {
            document.getElementById('chat-footer').style.display =
                'none';
        }
    }
}

export async function forgetUserData() {
    utils.deleteCookie(`$omb_agreed_policies_${store.bot._id}`);
    utils.deleteCookie(`$omb_conversation_${store.bot._id}`);
    utils.deleteCookie(`$omb_alias_${store.conversation._id}`);

    // localStorage.removeItem(
    //     `$omb_language_selected_${store.bot._id}`,
    // );

    // utils.deleteCookie(`$omb_user_${store.bot._id}`);

    $_omb.clearButtonsSection();

    loadBotProperties();

    await statusService.update(
        {
            origin: store.session.origin,
            online: false,
            typing: false,
            deleted: true,
            attended: {},
            userName: '',
        },
        true,
    );

    store.user = {};
    store.conversation = {};
    store.status = { attended: {} };
    store.app.lastMessageUserScroll = 0;
    store.app.typing.current = false;
    store.app.typing.chatTimer = null;
    store.app.typing.timer = null;

    $_omb.closeChatWindow();
}

export async function loadAgentProperties() {
    const agent = store.status.attended;

    if (agent.employee) {
        const headerAvatarElement = document.querySelector(
            '#chat-bot-avatar',
        );
        const headerNameElement = document.querySelector(
            '#bot-avatar-name',
        );
        const minimizedAvatarElement = document.querySelector(
            '#avatar-minimized',
        );
        const callToActionBubble = document.querySelector(
            '#call-to-action-bubble',
        );

        headerAvatarElement.setAttribute('src', agent.image);
        minimizedAvatarElement.setAttribute('src', agent.image);
        headerNameElement.innerHTML = xss(agent.name);
        callToActionBubble.style.display = 'none';
    }
}

export default {
    expandMenu,
    collapseMenu,
    expandForgetUserDataMenu,
    collapseForgetUserDataMenu,
    loadBotProperties,
    loadCustomerProperties,
    loadAgentProperties,
};
