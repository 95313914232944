'use strict';

import { store } from '../store';
import utils from '../utils';

export async function get() {
    const currentConversationId = utils.getCookie(
        `$omb_conversation_${store.bot._id}`,
    );

    if (currentConversationId) {
        const baseURL = `${store.api.url}/public/live/status/${store.bot._id}/${currentConversationId}`;
        const fetchOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `API-KEY ${store.api.key}`,
            },
        };
        const status = await (
            await fetch(baseURL, fetchOptions)
        ).json();

        if (status.data) {
            store.status = JSON.parse(status.data);
        }
    }
}

export async function update(status, publish = true) {
    if (store.conversation._id) {
        const baseURL = `${store.api.url}/public/live/status`;
        const fetchOptions = {
            method: 'POST',
            body: JSON.stringify({
                bot: store.bot._id,
                conversation: store.conversation._id,
                status,
                publish,
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `API-KEY ${store.api.key}`,
            },
        };

        await (await fetch(baseURL, fetchOptions)).json();
    }
}

export default {
    get,
    update,
};
