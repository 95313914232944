'use strict';

import { store } from '../store';
import utils from '../utils';

export async function init(options = { alias: null }) {
    if (!store.bot._id) {
        const bot = await get();

        store.bot = bot;
    }

    const currentConversationId = utils.getCookie(
        `$omb_conversation_${store.bot._id}`,
    );

    const currentAliasId = utils.getCookie(
        `$omb_alias_${currentConversationId}`,
    );

    const hasRandomAlias =
        store.bot.services &&
        store.bot.services.randomAlias &&
        store.bot.services.randomAlias.active;

    const locallyStoredLanguage = localStorage.getItem(
        `$omb_language_selected_${store.bot._id}`,
    );

    if (currentAliasId) {
        store.alias = store.bot.aliases.filter(
            alias =>
                alias._id.toString() === currentAliasId.toString(),
        )[0];
    } else if (hasRandomAlias && store.bot.aliases.length > 1) {
        const randomAliasIndex = Math.floor(
            Math.random() * store.bot.aliases.length,
        );

        store.alias = store.bot.aliases[randomAliasIndex];
    } else if (options.alias) {
        store.alias = store.bot.aliases.filter(
            alias =>
                alias._id.toString() === options.alias.toString(),
        )[0];
    } else {
        store.alias = store.bot.aliases[0];
    }

    store.i18n.languages = store.alias.languages;

    if (
        locallyStoredLanguage &&
        store.i18n.languages.includes(locallyStoredLanguage)
    ) {
        store.i18n.language = locallyStoredLanguage;
    } else {
        store.i18n.language = store.alias.languages[0];
    }
}

export async function get() {
    const baseURL = `${store.api.url}/public/bots`;

    const fetchOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `API-KEY ${store.api.key}`,
        },
    };

    const { bot } = await (await fetch(baseURL, fetchOptions)).json();

    return bot;
}

export default {
    init,
    get,
};
