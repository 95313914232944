'use strict';

import striptags from 'striptags';

import audioServices from '../services/audio';
import messageService from '../services/message';

let recorder = {};
let sendAudio = false;
let audioResponse = false;
let botAudioResponse = new Audio();

export function hasGetUserMediaBrowser() {
    if (!navigator.mediaDevices) {
        navigator.mediaDevices = {};
    }

    if (!navigator.mediaDevices.getUserMedia) {
        let getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia ||
            navigator.getUserMedia;
        return getUserMedia;
    } else {
        return navigator.mediaDevices.getUserMedia;
    }
}
export function recordAudioWebRTC() {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(() => {
        handlerAudioFunction();
    });
}
function handlerAudioFunction() {
    let newRecorder = {};

    botAudioResponse.pause();

    let setGlobalRecorder = () => {
        newRecorder.addEventListener(
            'dataavailable',
            onRecordingReady,
        );
        recorder = newRecorder;
        showRecordControls();
        recorder.start();
    };

    navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function(stream) {
            const options = {
                audioBitsPerSecond: 16000,
                mimeType: 'audio/webm',
            };
            newRecorder = new MediaRecorder(stream, options);
            setGlobalRecorder();
        });
}
export function sendAudioRecord() {
    sendAudio = true;
    audioResponse = true;
    recorder.stop();
    removeRecordControls();
}
export function cancelAudioRecord() {
    sendAudio = false;
    recorder.stop();
    removeRecordControls();
}
export function removeRecordControls() {
    document.getElementById('typing-bar').disabled = false;
    const micro = document.getElementById('chat-micro');
    const sendBackground = document.getElementById('send-background');
    const cancelVoice = document.getElementById('chat-cancel-voice');
    const sendVoice = document.getElementById('chat-send-voice');
    const counterVoice = document.getElementById(
        'chat-counter-voice',
    );
    sendBackground.style.width = '70px';
    const sendBackgroundPath = document.getElementById('st0');
    let d =
        'M0,51c0,0,5.7-3.7,13.4-25.7C21.2,3.2,28.2,0,41.4,0S70,0,70,0v51H0z';
    sendBackgroundPath.setAttribute('d', d);
    micro.style.display = 'block';

    cancelVoice.style.display = 'none';
    sendVoice.style.display = 'none';
    counterVoice.style.display = 'none';
    counterVoice.innerHTML = '0:00';
}
export function showRecordControls() {
    document.getElementById('typing-bar').disabled = true;

    const micro = document.getElementById('chat-micro');
    const sendBackground = document.getElementById('send-background');
    const cancelVoice = document.getElementById('chat-cancel-voice');
    const sendVoice = document.getElementById('chat-send-voice');
    const counterVoice = document.getElementById(
        'chat-counter-voice',
    );

    sendBackground.style.width = '240px';

    const sendBackgroundPath = document.getElementById('st0');
    let d =
        'M0,51c0,0,5.7-3.7,13.4-25.7C21.2,3.2,28.2,0,41.4,0S70,0,240,0v51H0z';
    sendBackgroundPath.setAttribute('d', d);
    micro.style.display = 'none';
    counterVoice.innerHTML = '0:00';
    cancelVoice.style.display = 'block';
    sendVoice.style.display = 'block';
    counterVoice.style.display = 'block';

    let timeInSeconds = 0;

    function incrementTime() {
        if (recorder.state !== 'recording') {
            timeInSeconds = 0;
            clearInterval(interval);
            return;
        }
        timeInSeconds += 1;

        let seconds = timeInSeconds % 60;
        let minutes = parseInt(timeInSeconds / 60);
        let dSecs = seconds > 9 ? seconds : '0' + seconds;

        counterVoice.innerHTML = minutes + ':' + dSecs;
    }

    let interval = setInterval(incrementTime, 1000);
}
async function onRecordingReady(e) {
    if (sendAudio) {
        let userRecordedAudio = {};
        userRecordedAudio.src = URL.createObjectURL(e.data);

        let blob = await fetch(userRecordedAudio.src).then(r =>
            r.blob(),
        );

        let blob_ = new Blob([blob], { type: 'audio/mp3' });

        let reader = new FileReader();
        reader.readAsDataURL(blob_);
        reader.onload = async () => {
            let base64Data = reader.result;
            base64Data = base64Data.split(',')[1];
            let transcript = await audioServices.sendSpeechToText(
                base64Data,
            );

            if (transcript) {
                if (transcript.length < 256) {
                    $_omb.printText(transcript, 'chat-message-user');

                    messageService.create(transcript);
                } else {
                    $_omb.addResponseMessage({
                        text: `El límite es 256 caracteres. Por favor intenta ser más preciso y directo al enviar el audio.`,
                    });
                }
            } else {
                $_omb.addResponseMessage({
                    text: `No te he entendido bien. ¿Me lo puedes repetir?`,
                });
            }
        };
        sendAudio = false;
    }
}
export function checkAudioResponse(message) {
    if (audioResponse) {
        prepareAudioResponse(message);
    }
}
async function prepareAudioResponse(message) {
    const messageHasText = message && message.text;
    const messageHasCards =
        message && message.payload && message.payload.cards;

    let txt = '';
    if (messageHasText) {
        message.text = striptags(message.text, [], '\n');
        txt = `${txt} ${message.text}`;
    }

    if (messageHasCards) {
        if (message.payload.cards.length == 1) {
            let shortAnswer = ``;
            txt = message.payload.cards[0].subtitle
                ? `${txt} - ${message.payload.cards[0].subtitle} ${shortAnswer}`
                : `${txt} ${shortAnswer}`;

            txt = message.payload.cards[0].title
                ? `${txt} - ${message.payload.cards[0].title} ${shortAnswer}`
                : `${txt} ${shortAnswer}`;
            txt = striptags(txt, [], '\n');
        } else {
            txt = `${txt} Descripción mas detallada en pantalla.`;
        }
    }
    let audioTTS = await audioServices.sendTextToSpeech(txt);
    let base64 = 'data:audio/ogg;base64,' + audioTTS.audioContent;

    botAudioResponse.src = `${base64}`;
    botAudioResponse.play();
    audioResponse = false;
}

export default {
    hasGetUserMediaBrowser,
    recordAudioWebRTC,
    sendAudioRecord,
    cancelAudioRecord,
    checkAudioResponse,
};
