'use strict';

const translation = {
    politicaDePrivacidad: 'Privacy Policy',
    politicaDePrivacidadOtro: 'Data Protection',
    seleccionarIdioma: 'Select language',
    olvidarMisDatos: 'Forget my data',
    sobreLosCreadores: 'About the creators',
    politicadePrivacidadMayus: 'PRIVACY POLICY',
    procedimientoIrreversible:
        'This procedure is irreversible, if you continue I will forget everything about you',
    seleccionaIdiomaChatbot:
        'Select the language in which you want your chatbot to speak',
    escribeTuPregunta: 'Write your question',
    escribiendo: 'Typing...',
    error:
        "Right now I can't help you, please reload the page, if I still don't respond, wait a few minutes. Sorry for the disturbances.",
};

export default translation;
