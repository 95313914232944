'use strict';

import { store } from '../store';

export function createHtmlMessages() {
    const messages = store.conversation.messages;
    const chatMessageBot = document.querySelector(
        '#chat-messages > .chat-message-bot',
    );
    const shouldAvoidBotMessage =
        messages.length === 1 && chatMessageBot;

    for (const message of messages) {
        if (message.sender_type !== 'User') {
            if (!shouldAvoidBotMessage) {
                $_omb.addResponseMessage(
                    message.message,
                    message._id,
                );
            }
        } else {
            $_omb.addUserMessage(message.message.text);
        }
    }

    setTimeout(() => {
        $_omb.scrollToBottom();
    }, 10);
}

export default {
    createHtmlMessages,
};
