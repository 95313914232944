'use strict';

export async function sendTextToSpeech(text) {
    let ttsApiKey = 'AIzaSyDUkRPRKoNHdTGIDsM17CNidKDZmzGtiPQ';
    let url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${ttsApiKey}`;
    let body = {
        input: {
            text: text,
        },
        voice: {
            languageCode: 'es-ES',
            ssmlGender: 'FEMALE',
            name: 'es-ES-Standard-A',
        },
        audioConfig: {
            audioEncoding: 'OGG_OPUS',
            speakingRate: 1.25,
            pitch: -2.0,
        },
    };
    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const respObj = await response.json();
        return respObj;
    } catch (e) {
        console.error(e);
    }
}

async function sendSpeechToText(audio64) {
    let apiKey = 'AIzaSyDUkRPRKoNHdTGIDsM17CNidKDZmzGtiPQ';
    let url = `https://speech.googleapis.com/v1p1beta1/speech:recognize?key=${apiKey}`;
    let body = {
        audio: {
            content: audio64,
        },
        config: {
            languageCode: 'es-ES',
            sampleRateHertz: 16000,
            encoding: 'MP3',
        },
    };
    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const respObj = await response.json();
        if (Object.keys(respObj).length !== 0) {
            return respObj.results[0].alternatives[0].transcript;
        } else {
            return false;
        }
    } catch (e) {
        console.error(e);
    }
}

export default {
    sendSpeechToText,
    sendTextToSpeech,
};
