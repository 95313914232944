'use strict';

import xss from 'xss';

import { store } from '../store';
import { getCookie } from '../utils';
import translations from '../data/i18n';

import userService from '../services/user.js';
import conversationService from '../services/conversation.js';

export function showMainView() {
    const agreedPoliciesCookie = getCookie(
        `$omb_agreed_policies_${store.bot._id}`,
    );

    if (!agreedPoliciesCookie) {
        showPolicyView();
    } else {
        addPrivacyMenuUrl();
        showLoadingView();
        showChatView();
    }
}

export function addPrivacyMenuUrl() {
    const chatDropdownPrivacyUrl = document.querySelector(
        '#chat-dropdown-policy-url',
    );
    chatDropdownPrivacyUrl.href = store.bot.gdpr.url.es;

    if (store.bot.gdpr.privacy_menu_text_other) {
        chatDropdownPrivacyUrl.innerHTML =
            translations[store.i18n.language][
                'politicaDePrivacidadOtro'
            ];
    }
}

export function showLoadingView() {
    const chatBody = document.querySelector('#chat-body');
    const loadingScreen = document.querySelector(
        '#chat-loading-screen',
    );
    const policyScreen = document.querySelector(
        '#chat-privacy-policy-screen',
    );

    loadingScreen.style.display = 'flex';
    policyScreen.style.display = 'none';
    chatBody.style.display = 'none';
}

export function hideLoadingView() {
    const loadingScreen = document.querySelector(
        '#chat-loading-screen',
    );

    loadingScreen.style.display = 'none';
}

export async function showChatView() {
    if (!store.user._id) {
        await userService.get();
    }

    if (!store.conversation._id) {
        await conversationService.get();
    }

    const chatBody = document.querySelector('#chat-body');
    const policyScreen = document.querySelector(
        '#chat-privacy-policy-screen',
    );

    policyScreen.style.display = 'none';
    chatBody.style.display = 'flex';

    hideLoadingView();
}

export function showPolicyView() {
    const chatBody = document.querySelector('#chat-body');
    const policyScreen = document.querySelector(
        '#chat-privacy-policy-screen',
    );
    const chatPrivacyText = document.querySelector(
        '#chat-privacy-policy-text',
    );
    const chatPrivacyUrl = document.querySelector(
        '#chat-privacy-policy-url',
    );

    const chatPrivacyButtonYes = document.querySelector(
        '#chat-privacy-policy-button-yes',
    );
    const chatPrivacyButtonNo = document.querySelector(
        '#chat-privacy-policy-button-no',
    );

    addPrivacyMenuUrl();

    chatPrivacyText.innerHTML = xss(store.bot.gdpr.message.es);
    chatPrivacyUrl.href = store.bot.gdpr.url.es;

    if (store.bot.gdpr.privacy_view_text) {
        chatPrivacyUrl.innerHTML = xss(
            store.bot.gdpr.privacy_view_text,
        );
    }

    if (store.bot.gdpr.accept_button_text) {
        chatPrivacyButtonYes.innerHTML = xss(
            store.bot.gdpr.accept_button_text,
        );
    }

    if (store.bot.gdpr.cancel_button_text) {
        chatPrivacyButtonNo.innerHTML = xss(
            store.bot.gdpr.cancel_button_text,
        );
    }

    if (store.bot.gdpr.accept_button_visibility === false) {
        chatPrivacyButtonYes.style.display = 'none';
    }

    if (store.bot.gdpr.cancel_button_visibility === false) {
        chatPrivacyButtonNo.style.display = 'none';
    }

    chatBody.style.display = 'none';
    policyScreen.style.display = 'flex';
}

export default {
    showMainView,
    showLoadingView,
    showPolicyView,
    showChatView,
};
