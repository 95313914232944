'use strict';

const urls = {
    production: {
        api: 'https://api.1millionbot.com/api',
        socket: 'https://socket.1millionbot.com',
    },
    staging: {
        api: 'https://staging-api.1millionbot.com/api',
        socket: 'https://socket-staging.1millionbot.com',
    },
    development: {
        api: 'http://localhost:3789/api',
        socket: 'http://localhost:1337',
    },
};

const getApiKeyFromParams = () => {
    if (
        process.env.NODE_ENV !== 'production' &&
        process.env.NODE_ENV !== 'staging'
    ) {
        const urlParams = new URLSearchParams(window.location.search);
        const key = urlParams.get('api_key');

        return key;
    }

    return '';
};

export const defaultStore = {
    api: {
        url: urls[process.env.NODE_ENV].api || urls.development.api,
        key: getApiKeyFromParams(),
    },
    socket: {
        url:
            urls[process.env.NODE_ENV].socket ||
            urls.development.socket,
        client: null,
    },
    bot: {},
    alias: {},
    user: {},
    conversation: {},
    status: {
        attended: {},
    },
    geolocation: {
        country: {
            name: '',
        },
        timezone: {
            name: '',
        },
        asn: {
            ip: '',
        },
    },
    i18n: {
        languages: [],
        language: '',
    },
    session: {
        origin: window.location.href,
    },
    app: {
        lastMessageUserScroll: 0,
        scripts: {},
        styles: '',
        typing: {
            current: false,
            chatTimer: null,
            timer: null,
        },
        help: {
            timer: null,
        },
        style: {
            buttonFontSize: '14px',
        },
    },
};

export const store = defaultStore;

export default { store, defaultStore };
