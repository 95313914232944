'use strict';

import { store } from '../store';

import statusService from './status';
import conversationService from './conversation';
import userService from './user';
import notificationService from './notification';

export async function create(message = null, event = null) {
    if (!store.status.attended.employee) {
        $_omb.printBotTypingLoader();
    }

    $_omb.scrollToBottom();

    store.app.typing.current = false;
    clearTimeout(store.app.typing.timer);
    clearTimeout(store.app.typing.chatTimer);

    if (!store.user._id) {
        await userService.create();
    }

    if (!store.conversation._id) {
        await conversationService.create();
    }

    const baseURL = `${store.api.url}/public/messages`;
    const fetchOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `API-KEY ${store.api.key}`,
        },
        body: JSON.stringify({
            conversation: store.conversation._id,
            bot: store.bot._id,
            sender_type: 'User',
            sender: store.user._id,
            language: store.i18n.language,
            url: store.session.origin,
            ...(message ? { message: { text: message } } : {}),
            ...(event ? { event } : {}),
        }),
    };

    await (await fetch(baseURL, fetchOptions)).json();

    await statusService.update(
        {
            origin: store.session.origin,
            online: true,
            typing: false,
            attended: store.status.attended,
            userName: store.user.name,
        },
        true,
    );

    if (store.status.attended && store.status.attended.employee) {
        await notificationService.create({
            bot: {
                id: store.bot._id,
                alias: store.alias._id,
                name: store.alias.name,
                image: store.alias.image,
            },
            employee: {
                id: store.status.attended.employee,
                name: store.status.attended.name,
                department: store.status.attended.department,
            },
            user: {
                name: store.user.name,
                id: store.user._id,
            },
            conversation: {
                id: store.conversation._id,
            },
            message,
        });
    }

    // .catch(error => {
    //     console.error('Error: ', error);
    //     return {
    //         response: [
    //             {
    //                 text:
    //                     translations[
    //                         store.i18n.language
    //                     ]['error'],
    //             },
    //         ],
    //     };
    // })
}

export default {
    create,
};
